.vinPathTableItem {
  border-bottom: 1px solid var(--sparky-grey-300);

  &.checked {
    background-color: rgba(13, 103, 248, 0.1);
  }

  .vinDetailBtn {
    margin: auto 0;
  }
}
