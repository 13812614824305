.vinPathPriceSection {
  width: 100%;
  padding: 0 12px;

  &.openState {
    background-color: var(--sparky-background);
  }

  .topPanel,
  .bottomPanel {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;

    .toggleBtn {
      margin-left: auto;
    }

    .priceScenariosContainer {
      transition: height ease-in-out 0.2s;
      overflow: hidden;

      .divider {
        padding-top: 16px;
      }
    }

    .priceHistory {
      transition: height ease-in-out 0.2s;
      overflow: hidden;
    }
  }
}
