.sortAcquisition {
  display: none;
  position: relative;
  box-shadow: var(--sparky-boxshadow-left);
  z-index: 500;

  &.isShow {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--sparky-grey-100);
    height: 100%;
    width: 18rem;
  }

  .sortAcquisitionHeader {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-12);
    background-color: #fff;
  }

  .sortAcquisitionBody {
    overflow-y: auto;
    width: inherit;

    .sortColumns,
    .columnOptions {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-04);
      margin: 0 var(--spacing-12);
      border-top: 1px solid var(--sparky-grey-100);
    }

    .sortColumns {
      border-top: 1px solid var(--sparky-grey-100);
      padding-top: var(--spacing-08);

      .sortColumnsHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .sortBy {
          display: flex;
          align-items: center;
          height: var(--spacing-32);
        }
      }
    }
  }
}
