.main {
  display: flex;
  min-height: 100%;
  padding-top: var(--nav-height);

  .nav {
    z-index: 101;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: var(--nav-height);
  }

  .content {
    display: flex;
    min-height: var(--content-height);
    z-index: 0;
    flex: 1;
    width: 100%;

    .page {
      --sidebar-width: 1rem;
      display: flex;
      justify-content: center;
      flex: 1;
      width: calc(100% - var(--sidebar-width));
      height: var(--content-height);
    }
  }
}
