.rangeOptions {
  display: grid;
  grid-template-columns: 1fr 8px 1fr;
  grid-column-gap: var(--spacing-04);
  font-size: var(--font-14);
  width: 100%;

  .minInput,
  .maxInput {
    width: 100%;
  }

  .connector {
    margin: auto 0 8px;
  }
}
