.noDataAvailable {
  background: url('../../VinDetailSidebar/NoVinAvaliableBackgroundImg.jpg') no-repeat center 30%;
  background-size: cover;
  border-radius: 16px;

  .externalLink {
    color: var(--sparky-blue-500);
    text-decoration: underline;
  }
}
