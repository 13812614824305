.vinPathTableContainer {
  position: relative;
  max-width: 1100px;

  .vinPathTable {
    display: flex;
    flex-direction: column;
    width: 100%;

    .message {
      margin-top: 16px;
    }
  }

  .vinPathVinDetail {
    box-shadow: var(--sparky-boxshadow-left);
    position: fixed;
    top: 133px;
    bottom: 0;
    right: 0;
    border: 1px solid var(--sparky-grey-300);
    background-color: var(--sparky-brand-white);
    z-index: 3;
    height: unset;
    transition: ease-in-out 0.2s;
  }
}
