.outageFormWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--spacing-08);
  padding: 60px 80px;

  .logoContainer {
    display: flex;
    color: var(--sparky-text-primary-white);
    flex-direction: column;
    align-items: center;
    margin-bottom: var(--spacing-48);

    .logo {
      height: 37px;
    }
  }

  .outageContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 30px 0;
  }
}
