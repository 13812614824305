.tooltipicon {
  margin-left: var(--spacing-04);
  transform: translateY(1px);
}

.popoverContainer {
  display: flex;
  flex-direction: column;
  width: 279px;
  row-gap: 6px;
}
