.radioField {
  --checkbox-width: 24px;

  display: inline-flex;
  flex-direction: column;
  width: fit-content;
  font-weight: 400;

  &.disabled {
    color: var(--sparky-grey-300);
    cursor: default;
  }

  .textField {
    display: flex;
    align-items: center;
    cursor: pointer;

    .label {
      font-size: 16px;
      margin-left: calc(var(--checkbox-width) * 0.5);
    }
  }

  .helpText {
    margin-left: calc(var(--checkbox-width) * 1.5);
    margin-top: 2px;
  }
}
