.segmentsSearch {
  display: flex;
  width: 100%;
}

.searchPopover {
  width: 100%;
  background-color: white;
  padding: 16px;
  border: 1px solid #d0d0d0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.popoverContent {
  width: var(--radix-popover-trigger-width);
  z-index: 201;
}

.searchItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  align-items: center;
  padding: 4px;

  &:hover {
    background-color: var(--sparky-grey-100);
    cursor: pointer;
  }
}

.emptySearchResults {
  padding-block: 8px;
  color: var(--sparky-grey-300);
}

.search {
  justify-self: left;
  font-size: 14px !important;
  width: 100%;
}

.highlight {
  background-color: var(--sparky-blue-100);
}

.searchPromptDivider {
  display: inline;
}

@media screen and (max-width: 800px) {
  .searchPrompt {
    display: flex;
    flex-direction: column !important;
  }

  .searchPromptDivider {
    display: none;
  }
}
