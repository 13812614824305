.authenticator {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logoContainer {
    display: block;
    position: relative;
    height: 20rem;
    width: 30rem;

    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .header {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }

  .signOut {
    position: absolute;
    top: var(--spacing-16);
    right: var(--spacing-16);
  }
}
