.columnOptions {
  height: auto;
  scroll-behavior: smooth;
  padding: 0 var(--spacing-12) var(--spacing-12);
  overflow-y: auto;

  .columnSectionContent {
    display: flex;
    gap: var(--spacing-08);
    flex-wrap: wrap;
  }
}
