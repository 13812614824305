.customizeSidebar {
  display: none;
  position: relative;
  box-shadow: var(--sparky-boxshadow-left);

  &.showCustomizeSidebar {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--sparky-grey-100);
    width: 20rem;
    height: calc(100% + 24px);
  }

  .header {
    position: relative;
    padding: var(--spacing-12);
    border-bottom: 1px var(--sparky-grey-100) solid;

    .headerContent {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-08);

      .title {
        display: flex;
        gap: var(--spacing-08);
        align-items: center;
      }
    }

    .closeButton {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
