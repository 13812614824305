.chip {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 3px;
  background-color: var(--sparky-brand-white);
  cursor: default;
  border-width: 1px;
  border-style: solid;
  height: 24px;

  &.small {
    font-size: 8px;
    height: 18px;
  }

  &.pill {
    border-radius: var(--spacing-32);
  }

  &.tag {
    border-radius: var(--spacing-02);
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1px;

    .textIcon {
      height: 10px;
      width: 10px;
      border-width: 1px;
      border-style: solid;
      border-radius: 10px;
    }

    .children {
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.small {
        height: 12px;
      }
    }

    .leftIconWrap,
    .rightIconWrap,
    .dismissWrap {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 15px;
      width: 15px;
      flex-shrink: 0;

      &.dismissWrap:hover {
        cursor: pointer;
        background-color: var(--bg-hover);
      }
    }

    .rightIconWrapBorderRadius,
    .dismissWrapBorderRadius {
      border-radius: 16px;
    }

    .handleLeftIconClick,
    .handleRightIconClick {
      cursor: pointer;
    }
  }
}

.chipBlue {
  color: var(--chip-blue-text-01);
  background-color: var(--chip-blue-bg);
  border-color: var(--chip-blue);

  &.filled {
    color: var(--chip-blue-text-02);
    border-color: var(--chip-blue-bg);
  }

  .content {
    .textIcon {
      background-color: var(--chip-blue);
      border-color: var(--chip-blue-bg);
    }
  }
}

.chipPurple {
  color: var(--chip-purple-text-01);
  background-color: var(--chip-purple-bg);
  border-color: var(--chip-purple);

  &.filled {
    color: var(--chip-purple-text-02);
    border-color: var(--chip-purple-bg);
  }

  .content {
    .textIcon {
      background-color: var(--chip-purple);
      border-color: var(--chip-purple-bg);
    }
  }
}

.chipPink {
  color: var(--chip-pink-text);
  background-color: var(--chip-pink-bg);
  border-color: var(--chip-pink);

  &.filled {
    color: var(--chip-pink-text);
    border-color: var(--chip-pink-bg);
  }

  .content {
    .textIcon {
      background-color: var(--chip-pink);
      border-color: var(--chip-pink-bg);
    }
  }
}

.chipTeal {
  color: var(--chip-teal-text);
  background-color: var(--chip-teal-bg);
  border-color: var(--chip-teal);

  &.filled {
    color: var(--chip-teal-text);
    border-color: var(--chip-teal-bg);
  }

  .content {
    .textIcon {
      background-color: var(--chip-teal);
      border-color: var(--chip-teal-bg);
    }
  }
}

.chipGrey {
  color: var(--chip-grey-text);
  background-color: var(--chip-grey-bg);
  border-color: var(--chip-grey);

  &.filled {
    border-color: var(--chip-grey-bg);
  }

  .content {
    .textIcon {
      background-color: var(--chip-grey);
      border-color: var(--chip-grey-bg);
    }
  }
}

.chipBlack {
  color: var(--sparky-brand-black);
  background-color: #e5e5e5;
  border-color: var(--sparky-brand-black);

  &.filled {
    border-color: #e5e5e5;
  }

  .content {
    .textIcon {
      background-color: var(--sparky-brand-black);
      border-color: #c3c3c3;
    }
  }
}

.chipOrange {
  color: var(--chip-orange-text-01);
  background-color: var(--chip-orange-bg);
  border-color: var(--chip-orange);

  &.filled {
    color: var(--chip-orange-text-02);
    border-color: var(--chip-orange-bg);
  }

  .content {
    .textIcon {
      background-color: var(--chip-orange);
      border-color: var(--chip-orange-bg);
    }
  }
}

.chipGreen {
  color: var(--chip-green-text);
  background-color: var(--chip-green-bg);
  border-color: var(--chip-green);

  &.filled {
    color: var(--chip-green-text);
    border-color: var(--chip-green-bg);
  }

  .content {
    .textIcon {
      background-color: var(--chip-green);
      border-color: var(--chip-green-bg);
    }
  }
}

.chipLime {
  color: var(--chip-lime-text);
  background-color: var(--chip-lime-bg);
  border-color: var(--chip-lime);

  &.filled {
    color: var(--chip-lime-text);
    border-color: var(--chip-lime-bg);
  }

  .content {
    .textIcon {
      background-color: var(--chip-lime);
      border-color: var(--chip-lime-bg);
    }
  }
}

.chipRed {
  color: var(--chip-red-text);
  background-color: var(--chip-red-bg);
  border-color: var(--chip-red);

  &.filled {
    border-color: var(--chip-red-bg);
  }

  .content {
    .textIcon {
      background-color: var(--chip-red);
      border-color: var(--chip-red-bg);
    }
  }
}

.chipYellow {
  color: var(--chip-yellow-text);
  background-color: var(--chip-yellow-bg);
  border-color: var(--chip-yellow);

  &.filled {
    border-color: var(--chip-yellow-bg);
  }

  .content {
    .textIcon {
      background-color: var(--chip-yellow);
      border-color: var(--chip-yellow-bg);
    }
  }
}

.chipNavy {
  color: var(--chip-navy-text);
  background-color: var(--chip-navy-bg);
  border-color: var(--chip-navy-text);

  &.filled {
    border-color: var(--chip-navy-bg);
  }

  &.selected {
    color: var(--sparky-brand-white);
    border-color: var(--border-active-01);
    background: var(--bg-selected);
  }

  &.notSelected {
    border-color: var(--border-subtle-01);
    background: var(--bg);
  }

  .content {
    .textIcon {
      background-color: var(--chip-navy);
      border-color: var(--chip-navy-bg);
    }
  }
}

.outline {
  background-color: unset;
}

.text {
  border-color: var(--sparky-brand-white);
  background-color: var(--sparky-brand-white);

  .content {
    .otherVariants {
      //color: var(--sparky-brand-black);
    }
  }
}

.selected,
.notSelected {
  cursor: pointer;
}

.handleClick {
  cursor: pointer;
}
