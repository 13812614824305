@use '../styles/layouts.scss';

.toastContainer {
  position: relative;
  align-items: center;
  display: inline-flex;
  height: 62px;
  padding: 8px 16px;
  margin: var(--spacing-12);
  border: none;
  border-radius: var(--border-radius-04);
  box-shadow: var(--elevation-3);
  text-align: center;
  overflow-wrap: anywhere;
  width: fit-content;
  pointer-events: auto;
  color: var(--sparky-text-white);

  &.default {
    background-color: var(--bg-selected);
  }

  &.success {
    background-color: var(--status-success);
  }

  &.error {
    background-color: var(--status-error);
  }

  &.warning {
    background-color: var(--status-warning);
  }

  &.info {
    background-color: var(--status-info);
  }

  .buttonContainer {
    display: flex;
    align-items: center;
    gap: 4px;
    padding-left: 24px;

    .closeButton[data-disabled='false']:hover {
      background-color: rgb(231, 234, 236, 0.2);
    }
  }
}

.toastIcon {
  margin-right: 8px;
}
