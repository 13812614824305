// Interbrand styling overrides
// These should eventually be integrated into the design system
body {
  --color-text-primary: #000 !important;
  --color-blue-4: #0d2b41 !important;

  .lx-button {
    border-radius: var(--border-radius-10);

    outline: none;

    // Custom outline
    &:focus {
      box-shadow: 0 0 0 1px #fff, 0 0 0 3px var(--color-outline);
    }

    &.lx-button--solid {
      background-color: var(--color-blue-4);
      border-color: transparent;
      color: #fff;

      &:hover {
        background-color: #0d2b41dd;
        border-color: transparent;
      }
    }

    &.lx-button--outline {
      border-color: var(--color-blue-4);
      color: var(--color-blue-4);

      &:hover {
        border-color: var(--color-blue-4);
        background-color: #0d2b4111;
      }
    }

    &.lx-button--outline,
    &.lx-button--solid {
      &.lx-button--disabled {
        background-color: var(--color-neutral-2);
        border-color: var(--color-neutral-2);
        color: var(--color-neutral-4);
      }
    }
  }

  .toggle-button-group .toggle-button--selected {
    color: #0d2b41;

    &:after {
      border-color: #0d2b41;
    }
  }

  [data-reach-tab-list] {
    margin-left: var(--spacing-04);
  }

  [data-reach-tab] {
    border: none;
    border-bottom: 2px solid #e5e7eb;
    padding: 0 var(--spacing-16);
    background-color: transparent;
    padding-bottom: var(--spacing-12);

    &:first-child {
      border-top-left-radius: 0;
    }

    &:last-child {
      border-top-right-radius: 0;
      border-right: none;
    }

    &[data-selected] {
      border-bottom-color: #2f80ed;
      background-color: #fff;
    }
  }

  [data-reach-tab-panel] {
    background-color: #fff;
    border: none;
  }
}
