.suggestionsPanel {
  width: 100%;
  padding: var(--spacing-16);

  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: var(--border-radius);
  background-color: #eef5ff;

  .header {
    display: flex;
    align-items: center;
    gap: var(--spacing-08);
    margin-bottom: var(--spacing-24);

    .dismissButton {
      margin-left: auto;
    }
  }

  .suggestionList {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-12);
  }
}
