.inventoryPage {
  display: flex;
  width: 100%;
  height: 100%;

  overflow: auto;

  .inventoryContent {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 24px 24px;
    transition: all ease-in-out 0.2s;
    overflow: auto;
    overflow-y: hidden;

    &.compact {
      padding: var(--spacing-12);
    }

    &.withVINDetailSidebar {
      padding-right: 0;
    }

    .vinDetailSidebar {
      margin-top: 24px;

      &.compactVINDetailSidebar {
        margin-top: 12px;
      }
    }
  }

  .inventoryVINDetail {
    box-shadow: var(--elevation-4);
  }
}

.unsavedTableViewModal {
  position: relative;
  display: flex;
  width: 750px;
  flex-direction: column;
  gap: var(--spacing-12);
  padding: var(--spacing-32);
  align-items: center;
  border-radius: var(--border-radius-04);
  background-color: var(--sparky-brand-white);

  .buttonContainer {
    display: flex;
    align-items: center;
    width: 100%;
    gap: var(--spacing-16);

    div:first-child {
      margin-right: auto;
    }
  }

  .closeModalButton {
    position: absolute;
    top: var(--spacing-08);
    right: var(--spacing-08);
  }
}
