.chartContainer {
  height: 100%;
  width: 100%;

  .relativeContainer {
    width: 100%;
    height: 100%;
    position: relative;

    &.hasLegend {
      height: calc(100% - 50px);
    }

    .absoluteContainer {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
    }
  }
}
