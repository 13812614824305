.noData {
  width: 100%;
  height: 100%;
  display: flex;
  padding-bottom: 5.6rem;
  justify-content: flex-end;

  .lines {
    margin-left: 4rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .zero {
      transform: translate(-20px, -6px);
    }
  }

  .noDataText {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 145px;
    background-color: var(--sparky-brand-white);
    padding: var(--spacing-12);
    top: 0%;
    left: calc(50% + 1.5rem);
    transform: translate(-50%, -50%);

    span {
      color: var(--sparky-grey-200);
    }
  }

  .ruler {
    height: 0;
    border-bottom: 1px solid var(--sparky-grey-100);
    width: 100%;
  }
}
