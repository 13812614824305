.upgradeCTA {
  display: flex;

  .background {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    background-size: cover;
    backdrop-filter: blur(7px);
  }

  .actionPanel {
    display: flex;
    flex-direction: row;
    top: 10%;
    left: 10%;
    position: absolute;
    height: 80%;
    width: 80%;
    background: url('VMBIUpgradeImage.png');
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 200;
    border-radius: 12px;
    container: panel / inline-size;

    .left,
    .right {
      flex: 1;
      margin: 54px;
    }

    .right {
      flex-shrink: 1;
    }

    .left {
      flex-grow: 1;
      user-select: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 40px;

      .requestButton {
        background-color: var(--sparky-red-400);
        width: 100%;
        font-size: 20px;
        font-weight: 700;
        padding: 25px;
        color: white;
        border-radius: 8px;
        border: none;

        &:hover {
          background-color: var(--sparky-red-500);
          cursor: pointer;
        }
      }

      .contactUs {
        color: var(--sparky-blue-500);
        text-decoration: underline;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

@container panel (inline-size < 900px) {
  .right {
    width: 15% !important;
    margin: 0 !important;
  }

  .left {
    gap: 30px !important;
  }

  .mainText {
    font-size: 18px !important;
  }

  .secondaryText {
    font-size: 12px !important;
  }
}
