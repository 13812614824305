.dealerSelectListItem {
  position: relative;
  display: grid;
  grid-gap: var(--spacing-12);
  grid-template-columns: auto 1fr auto auto;
  align-items: center;

  padding: 0 var(--spacing-08) 0 var(--spacing-08);
  cursor: pointer;

  font-size: var(--font-14);
  color: var(--text-primary);

  > span {
    text-wrap: wrap;
  }

  .dealerTypeIcon {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    height: 1.25rem;
    width: 1.25rem;
    border-radius: 100%;
    color: white;
    font-size: var(--font-12);
  }

  &:hover,
  &.focus {
    background-color: var(--sparky-grey-100);
  }

  &.activeDealer {
    font-weight: bold;
    color: var(--sparky-text-navy);
  }

  .highlight {
    color: var(--color-red-1);
  }
}

.prospect {
  border: 1px dashed var(--sparky-grey-400) !important;
  color: var(--sparky-grey-400) !important;
}

.none {
  border: 1px dashed var(--sparky-grey-400) !important;
  color: var(--sparky-grey-400) !important;
}
