.sliderRoot {
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  height: 20px;
  width: 324px;

  .sliderTrack {
    background-color: var(--sparky-grey-300);
    position: relative;
    flex-grow: 1;
    border-radius: 9999px;
    height: 3px;
  }

  .sliderRange {
    position: absolute;
    border-radius: 100%;
    height: 100%;
    background-color: var(--sparky-blue-400);
  }

  .sliderThumb {
    display: block;
    width: var(--spacing-24);
    height: var(--spacing-24);
    // box-shadow: 0 2px 10px var(--sparky-blue-400);
    cursor: grabbing;
    background-color: var(--sparky-brand-black);
    border-radius: 100%;
  }

  //   .sliderThumb:hover {
  //     box-shadow: 0 0 0 5px var(--sparky-blue-500);
  //   }
  .sliderThumb:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--sparky-blue-300);
  }
}
