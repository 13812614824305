.skeleton {
  display: inline-block;
  overflow: hidden;
  position: relative;

  background-color: var(--color-neutral-3);
  line-height: 1;
  vertical-align: text-bottom;

  width: 100%;
  height: 100%;

  &:before {
    $bar-width: 400px;

    content: '';
    position: absolute;
    top: 0;
    left: -$bar-width;
    height: 100%;
    width: $bar-width;

    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0)
    );
    animation: skeleton-anim 3s ease-in-out infinite;

    @media (prefers-reduced-motion) {
      display: none;
    }

    @keyframes skeleton-anim {
      0% {
        left: -$bar-width;
      }

      70%,
      100% {
        left: 100%;
      }
    }
  }
}
