.signInFormWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--spacing-08);
  padding: 60px 80px;

  .signInForm {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    text-align: left;
    width: 100%;

    .logoContainer {
      display: flex;
      color: var(--sparky-text-primary-white);
      flex-direction: column;
      align-items: center;
      margin-bottom: var(--spacing-48);

      .logo {
        height: 37px;
      }
    }

    .textInput {
      width: 100%;
    }

    .errorMessage {
      color: var(--sparky-red-300);
    }

    .signInButton {
      margin-top: var(--spacing-32);
      width: 100%;

      &:hover {
        background-color: var(--sparky-navy-200) !important;
      }
    }
  }

  .resetPwd:hover {
    background-color: var(--sparky-navy-400) !important;
  }

  .helpLink {
    padding: 14px 12px;
    font-weight: 500;

    &:hover {
      font-weight: 700;
    }
  }
}
