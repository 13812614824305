.segmentPopperWrapper {
  width: 747px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-height: 70vh;

  .segmentContent {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    gap: 24px;
    padding: 0 16px 16px;

    .segmentSelectionText {
      padding-bottom: 4px;
    }

    .searchField {
      width: 100%;
    }

    .rightDivider {
      border-right: 1px solid var(--sparky-grey-200);
    }

    .segmentTileSection {
      overflow-y: auto;
      overflow-x: hidden;
      width: 100%;
      gap: 4px;
      column-gap: 8px;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media screen and (max-width: 728px) {
  .segmentPopperWrapper {
    width: 380px;

    .segmentContent {
      .segmentTileSection {
        grid-template-columns: 1fr;
      }
    }
  }
}
