.signInPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: var(--gap-md);
  position: relative;

  .background {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%),
      url('SignInPageBackground.jpg'), lightgray 50% / cover no-repeat;
    background-size: cover;
    filter: blur(7px);
  }
}
