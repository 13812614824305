.response {
  background-color: var(--sparky-grey-100);
  padding: 12px 16px;
  display: flex;
  column-gap: 16px;
  border-radius: 24px;
  align-items: flex-start;
  max-width: calc(100% - 56px);

  .markdownContainer {
    display: flex;
    flex-direction: column;
    overflow-x: auto;

    // css for the markdown injections in the chat response

    strong {
      font-weight: 600;
    }

    p {
      padding: 4px 0;
      font-weight: 400;
    }

    table {
      border-collapse: collapse;
      background-color: #fff;
      margin: 12px 0;

      th,
      td {
        padding: 6px;
        border: 1px solid var(--sparky-grey-300);
        font-size: 14px;
      }

      tr {
        background-color: var(--sparky-grey-100);
      }

      tr:nth-child(2n -1) {
        background-color: var(--sparky-grey-200);
      }

      thead {
        th {
          font-weight: 600;
        }

        tr {
          background-color: var(--sparky-grey-100) !important;
        }
      }
    }

    ol {
      list-style: decimal;
      padding-left: 2rem;
      margin: 4px 0;
    }

    ul {
      margin: 8px 0;

      li {
        list-style-type: disc;
      }
    }
  }
}

.lexIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: white;
  height: 24px;
  width: 24px;
  position: relative;

  svg {
    transform: scale(0.75);
  }
}
