.marketmaps {
  display: flex;
  height: 100vh;
  border-radius: 0;
  width: 100vw;
  max-width: 100%;
  position: relative;
  background-color: var(--sparky-grey-100);
  pointer-events: all;

  .closeButton {
    position: absolute;
    top: 24px;
    right: 22px;
    z-index: 1;
  }

  .titleContainer {
    display: flex;
    position: absolute;
    top: 36px;
    left: 36px;
    z-index: 1;
    column-gap: var(--spacing-08);
    align-items: center;

    svg {
      transform: scale(2);
      margin-left: 6px;
      margin-right: 6px;
    }

    .beta {
      padding: var(--spacing-04) var(--spacing-08);
      border: 1px solid var(--sparky-red-400);
      border-radius: 99px;
      color: var(--sparky-red-400);
      font-size: var(--font-12);
      font-weight: bold;
      display: inline-block;
    }
  }

  .dealerSelectContainer {
    display: flex;
    position: absolute;
    top: 26px;
    right: 72px;
    z-index: 1;
  }

  .metricSelectors {
    display: flex;
    column-gap: var(--spacing-12);
    position: absolute;
    top: 72px;
    left: 36px;
    z-index: 1;

    .metricSelectorsButton {
      border-radius: 2px;
      box-shadow: var(--elevation-1);
      border: 1px solid var(--sparky-blue-400, #888);

      &.inactive {
        span {
          color: var(--sparky-text-secondary);
        }
        background-color: var(--sparky-brand-white);
        border: 1px solid var(--sparky-grey-400, #888);
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}

// Modal Overrides
[aria-label='Lexica Market Maps'] {
  border-radius: 0 !important;
}

.mapboxgl-ctrl-attrib-inner {
  display: none !important;
}
