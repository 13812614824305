.textToggle {
  padding: 4px;
  background-color: var(--layer-01);
  border-radius: 24px;
  display: flex;
  align-items: center;
  gap: 4px;

  &:hover {
    cursor: pointer;
    background-color: var(--bg-hover);
  }
}

.textToggleOption {
  display: flex;
  align-items: center;
  padding: 2px 18px;
  border-radius: 24px;
}

.textToggleActive {
  background-color: white;
}

.label {
  user-select: none;
}
