.selectRangeOptions {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);

  .sliderRoot {
    position: relative;
    display: flex;
    align-items: center;
    user-select: none;
    touch-action: none;
    height: 20px;
    width: 66.66%;

    .sliderTrack {
      background-color: var(--sparky-grey-300);
      position: relative;
      flex-grow: 1;
      border-radius: 9999px;
      height: 3px;
    }

    .sliderRange {
      position: absolute;
      border-radius: 100%;
      height: 100%;
      background-color: var(--sparky-blue-400);
    }

    .sliderThumb {
      display: block;
      width: var(--spacing-24);
      height: var(--spacing-24);
      // box-shadow: 0 2px 10px var(--sparky-blue-400);
      cursor: grabbing;
      background-color: var(--sparky-brand-black);
      border-radius: 100%;
    }

    //   .sliderThumb:hover {
    //     box-shadow: 0 0 0 5px var(--sparky-blue-500);
    //   }
    .sliderThumb:focus {
      outline: none;
      box-shadow: 0 0 0 2px var(--sparky-blue-300);
    }
  }
}

.tooltip {
  z-index: 2;
}

.option {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.muted {
    opacity: 0.5;
  }

  .itemLabel {
    word-break: break-word;
  }

  .itemCount {
    color: var(--color-neutral-5);
    white-space: nowrap;
    margin-left: var(--spacing-08);
  }

  input[type='radio'] {
    margin: 0 1px;
  }
}

.selectOptions {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04);
}

.rangeOptions {
  display: grid;
  grid-template-columns: 1fr 8px 1fr;
  grid-column-gap: var(--spacing-04);
  font-size: var(--font-14);
  width: 100%;

  .minInput,
  .maxInput {
    width: 100%;
  }

  .connector {
    margin: auto 0 8px;
  }
}
