.image {
  height: 60px;
  margin: 0 0 auto;
  aspect-ratio: 4 / 3;
  border-radius: 4px;
  border: 1px solid var(--sparky-grey-100);
  overflow: hidden;
}

.dotline {
  display: flex;
  width: 100%;
  height: 1px;
  border-top: 1px dotted var(--sparky-navy-400);
}

.probabilityOfSale {
  width: 100px;
  flex-shrink: 0;
}
