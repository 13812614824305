.resetCompletePwdWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--spacing-08);
  padding: 60px 80px;
  color: var(--sparky-text-primary-white);

  .header {
    margin-bottom: var(--spacing-48);
  }

  .resetCompletePwdForm {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: var(--spacing-32);

    .message {
      padding: 0 6px;
    }

    .textInputWrapper {
      width: 100%;

      .textInput {
        width: 100%;
      }

      .errorMessage {
        color: var(--sparky-red-300);
      }
    }

    .summitButton {
      background-color: var(--sparky-brand-white);
      color: var(--sparky-navy-400);
      width: 100%;

      &:hover {
        background-color: var(--sparky-navy-200) !important;
      }
    }
  }
}
