.tablePagination {
  display: flex;
  gap: var(--spacing-04);
  align-items: center;
  justify-content: flex-end;
  padding: var(--spacing-08);
  background-color: #fff;
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  border-top: 1px solid var(--sparky-grey-100);
  border-bottom: 1px solid var(--sparky-grey-100);
  height: 3.5rem;
  z-index: 1;

  .pageSelectInput {
    width: fit-content;
    margin-left: var(--spacing-04);
  }
}
