.iconButton {
  $border-width: 1px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  background-color: unset;

  transition: all ease-in-out 0.2s;
  cursor: pointer;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
  }

  .icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &.sm {
    border-radius: var(--border-radius-04);
    font-size: 16px;
    height: 24px;
    width: 24px;
    padding: calc(var(--spacing-04) - $border-width);
  }

  &.md {
    border-radius: var(--border-radius-08);
    font-size: 20px;
    height: 44px;
    width: 44px;
    padding: calc(var(--spacing-08) - $border-width);
  }

  &.lg {
    border-radius: var(--border-radius-08);
    font-size: 24px;
    height: 48px;
    width: 48px;
    padding: calc(var(--spacing-12) - $border-width);
  }

  &.iconOnly {
    color: var(--sparky-navy-400);

    .icon[data-disabled='false']:hover {
      background-color: var(--sparky-navy-100);
    }

    &.sm {
      padding: 2px;

      .icon {
        border-radius: var(--border-radius-04);
        padding: calc(var(--spacing-04) - 2px);
      }
    }

    &.md {
      padding: 6px;

      .icon {
        border-radius: var(--border-radius-08);
        padding: calc(var(--spacing-04) - 6px);
      }
    }

    &.lg {
      padding: 3px;

      .icon {
        border-radius: var(--border-radius-08);
        padding: calc(var(--spacing-04) - 3px);
      }
    }
  }

  &.outline {
    color: var(--sparky-navy-400);
    border: $border-width solid var(--sparky-grey-200);
    background-color: var(--sparky-brand-white);

    &:hover:not([disabled]) {
      color: var(--sparky-navy-600);
      border-color: var(--sparky-navy-400);
    }
  }

  &.filled {
    border: $border-width solid var(--sparky-navy-400);
    color: var(--sparky-brand-white);
    background-color: var(--sparky-navy-400);

    &:hover:not([disabled]) {
      background-color: var(--sparky-navy-600);
    }
  }
}
