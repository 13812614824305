.chartTooltip {
  display: flex;
  flex-direction: column;
  min-width: 231px;
  padding: 12px;
}

.chartTooltipItem {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 0;
  border-bottom: 1px solid var(--sparky-grey-100);

  .circle {
    height: 8px;
    width: 8px;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
  }

  .rect {
    height: 4px;
    width: 12px;
  }

  .dotLine {
    border-bottom: 3px dotted;
    width: 12px;
  }

  .value {
    margin-left: auto;
  }
}
