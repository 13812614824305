.vinPathPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .pageHeader {
    width: 100%;
    padding: 36px 24px;
  }

  .vinPath {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: var(--content-height);
    width: 100%;
    background-color: var(--sparky-background);
    overflow: scroll;
    row-gap: 24px;

    .scrollToTopBtn {
      position: sticky;
      bottom: 48px;
      right: 48px;
      margin-left: auto;
      width: fit-content;
      box-shadow: var(--elevation-3);
    }
  }
}
