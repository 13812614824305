.sortableColumn {
  display: flex;
  align-items: center;
  height: var(--spacing-48);
  padding: 0 var(--spacing-08);
  background-color: var(--sparky-blue-100);

  &.isDragging {
    opacity: 0.5;
  }

  &.dragOverlay {
    box-shadow: var(--elevation-2);
  }

  .label {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sortContainer {
    color: var(--sparky-blue-400);
    display: flex;
    align-items: center;
    gap: var(--spacing-04);
  }

  .ascIcon,
  .descIcon {
    color: #fff;
    height: var(--spacing-24);
    width: var(--spacing-24);
    padding: var(--spacing-02);
    border-radius: var(--spacing-12);
    background-color: var(--sparky-blue-400);

    &:hover {
      background-color: var(--sparky-blue-500);
    }
  }
}
