.dealersidebar {
  width: 300px;
  height: 100%;

  .tabs {
    display: flex;
    flex: 1;
    margin: var(--spacing-12) var(--spacing-12) 0;
  }

  .tabContentContainer {
    display: flex;
    flex-direction: column;
    padding-top: var(--spacing-24);

    .keyDealerTitle {
      display: flex;
      margin: var(--spacing-08) 0;
    }

    .dealerSelect {
      display: flex;
      flex-direction: column;
      row-gap: var(--spacing-16);
      margin-bottom: var(--spacing-16);

      > span {
        padding-left: var(--spacing-04);
      }
    }

    .dealerFilter {
      width: 100%;
      margin: 0 0 8px;
    }

    .dealerList {
      display: flex;
      flex-direction: column;
      row-gap: var(--spacing-04);
      max-height: 310px;
      overflow-y: auto;
    }
  }
}

.spacer {
  height: var(--spacing-24);
}

.accordian {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-08) 0;
  row-gap: var(--spacing-08);
  border-top: 1px solid var(--sparky-grey-200);

  .accordianHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-04) 0 var(--spacing-08);

    .accordianTitle {
      display: flex;
      align-items: center;
    }

    .accordianControl {
      cursor: pointer;
      transform: rotate(90deg);

      &.closed {
        transform: rotate(270deg);
      }
    }
  }
}

.option {
  padding: var(--spacing-08);
  column-gap: var(--spacing-08);
  display: flex;
  align-items: center;

  &:hover {
    background-color: var(--sparky-grey-100);
    cursor: pointer;
  }
}

.optionsWarning {
  margin: var(--spacing-08);
}
