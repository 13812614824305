.legend {
  display: flex;
  column-gap: var(--spacing-16);
  margin-left: var(--spacing-32);
  margin-top: var(--spacing-08);

  .legendItem {
    display: flex;
    align-items: center;
  }

  .colorCircled {
    height: var(--spacing-08);
    width: var(--spacing-08);
    border-radius: 100%;
    margin-right: var(--spacing-08);
    min-width: 8px;
  }

  .colorDash {
    height: var(--spacing-04);
    width: var(--spacing-12);
    border-radius: 2px;
    margin-right: var(--spacing-08);

    &.dashedLine {
      width: var(--spacing-04);
      height: 3px;

      &:first-child {
        margin-right: var(--spacing-04);
      }
    }
  }
}
