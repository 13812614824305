@import '../../Sparky/styles/layouts';

.dealerSelectPage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--content-height);
  padding: var(--gap-md);
  width: var(--floating-panel-width);

  .panel {
    width: 100%;
    background-color: #fff;
    border-radius: var(--border-radius);
    border: 1px solid var(--color-neutral-4);
    overflow: hidden;
    padding: 16px;

    .header {
      margin-bottom: var(--spacing-16);
    }
  }
}
