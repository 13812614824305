.chatPage {
  --form-height: 6rem;

  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;

  .centreContent {
    // max-width: 40rem;
    margin: auto;
    padding: var(--spacing-16);
    min-height: calc(100% - var(--form-height));
  }

  .chat {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-12);
  }

  .form {
    position: sticky;
    bottom: 0;
    right: 0;
    left: 0;
    height: var(--form-height);

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #fff;
    border-top: 1px solid var(--color-neutral-4);
    padding: var(--spacing-08);
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.16);

    .input {
      width: 100%;
      max-width: 40rem;

      .sendIcon {
        color: var(--color-neutral-6);
        margin: 0 var(--spacing-08);
      }
    }

    .submitButton {
      --size: 3rem;
      height: var(--size);
      width: var(--size);

      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;

      border-radius: 50%;
      background-color: var(--color-blue-3);
      color: #fff;
      cursor: pointer;

      svg {
        display: block;
      }
    }
  }
}
