:root {
  // OLD BORDER RADIUS
  --border-radius: 0.25rem;
  --border-radius-10: 0.625rem;

  // NEW BORDER RADIUS
  --border-radius-02: 0.125rem;
  --border-radius-04: 0.25rem;
  --border-radius-08: 0.5rem;
  --border-radius-16: 1rem;
  --border-radius-32: 2rem;

  /* Font sizes */
  --font-6: 0.375rem;
  --font-8: 0.5rem;
  --font-10: 0.625rem;
  --font-12: 0.75rem;
  --font-14: 0.875rem;
  --font-16: 1rem;
  --font-18: 1.125rem;
  --font-20: 1.25rem;
  --font-24: 1.5rem;
  --font-32: 2rem;
  --font-40: 2.5rem;
  --font-48: 3rem;
  --font-56: 3.5rem;

  /* Spacing */
  --spacing-02: 0.125rem;
  --spacing-04: 0.25rem;
  --spacing-08: 0.5rem;
  --spacing-12: 0.75rem;
  --spacing-16: 1rem;
  --spacing-24: 1.5rem;
  --spacing-32: 2rem;
  --spacing-40: 2.5rem;
  --spacing-48: 3rem;
  --spacing-56: 3.5rem;
  --spacing-64: 4rem;

  /* Text colors */
  --color-text-primary: #0b203b;
  --color-text-secondary: #707070;
  --color-text-brand: #e2231a;
  --color-text-light: #8c97ba;

  /* Colors: */
  --color-neutral-1: #fafafa;
  --color-neutral-2: #f2f2f2;
  --color-neutral-3: #eaeaea;
  --color-neutral-4: #d0d0d0;
  --color-neutral-5: #8f8f8f;
  --color-neutral-6: #707070;

  --color-blue-1: #ccd1e0;
  --color-blue-2: #455486;
  --color-blue-3: #0b203b;
  --color-blue-4: #00152e;

  --color-red-1: #e2231a;
  --color-red-2: #bf2126;

  // Sparky Colour Palette
  --sparky-red-100: #ffe5e5;
  --sparky-red-200: #ffb2b2;
  --sparky-red-300: #ff8080;
  --sparky-red-400: #f00;
  --sparky-red-500: #b20000;
  --sparky-red-600: #8c0000;
  --sparky-red-700: #600;

  // Red brand
  --sparky-brand-100: #fee7e9;
  --sparky-brand-200: #fcb8bd;
  --sparky-brand-300: #f98991;
  --sparky-brand-400: #f41424;
  --sparky-brand-500: #ab0e19;
  --sparky-brand-600: #860b14;
  --sparky-brand-700: #62080e;

  --sparky-orange-100: #fff8f2;
  --sparky-orange-200: #fed6b2;
  --sparky-orange-300: #fdb97e;
  --sparky-orange-400: #fd7800;
  --sparky-orange-500: #b15400;
  --sparky-orange-600: #8b4200;
  --sparky-orange-700: #653000;

  --sparky-yellow-100: #fffbed;
  --sparky-yellow-200: #fef4d2;
  --sparky-yellow-300: #fde9a6;
  --sparky-yellow-400: #fcd34d;
  --sparky-yellow-500: #b09436;
  --sparky-yellow-600: #7e6a27;
  --sparky-yellow-700: #3f3513;

  --sparky-green-100: #e8f5ee;
  --sparky-green-200: #dcf1e5;
  --sparky-green-300: #8bcfa8;
  --sparky-green-400: #189f51;
  --sparky-green-500: #116f39;
  --sparky-green-600: #0d572d;
  --sparky-green-700: #0a4020;

  --sparky-lime-100: #f4faea;
  --sparky-lime-200: #e9f5da;
  --sparky-lime-300: #bce07e;
  --sparky-lime-400: #90cc28;
  --sparky-lime-500: #658f1c;
  --sparky-lime-600: #4f7016;
  --sparky-lime-700: #3a5210;

  --sparky-turqouise-100: #e7f5f6;
  --sparky-turqouise-200: #b7e2e3;
  --sparky-turqouise-300: #87cfd1;
  --sparky-turqouise-400: #10a0a2;
  --sparky-turqouise-500: #0b7071;
  --sparky-turqouise-600: #095859;
  --sparky-turqouise-700: #064041;

  --sparky-teal-100: #e6eef0;
  --sparky-teal-200: #b3ccd2;
  --sparky-teal-300: #80aab3;
  --sparky-teal-400: #015668;
  --sparky-teal-500: #013c49;
  --sparky-teal-600: #012f39;
  --sparky-teal-700: #00222a;

  --sparky-blue-100: #e7f0fe;
  --sparky-blue-200: #c2d9fd;
  --sparky-blue-300: #86b3fb;
  --sparky-blue-400: #0d67f8;
  --sparky-blue-500: #0a4dba;
  --sparky-blue-600: #07347c;
  --sparky-blue-700: #052457;

  --sparky-navy-50: #f5f7f7;
  --sparky-navy-100: #e7eaec;
  --sparky-navy-200: #b6bfc6;
  --sparky-navy-300: #8695a0;
  --sparky-navy-350: #4a6071;
  --sparky-navy-400: #0d2b41;
  --sparky-navy-500: #091e2e;
  --sparky-navy-600: #071824;
  --sparky-navy-700: #05111a;

  --sparky-purple-100: #f6e7fe;
  --sparky-purple-200: #e5b6fd;
  --sparky-purple-300: #d486fb;
  --sparky-purple-400: #a90df8;
  --sparky-purple-500: #7609ae;
  --sparky-purple-600: #5d0788;
  --sparky-purple-700: #440563;

  --sparky-pink-100: #ffecf7;
  --sparky-pink-200: #ffc6e8;
  --sparky-pink-300: #ff9fd9;
  --sparky-pink-400: #ff40b2;
  --sparky-pink-500: #b22d7d;
  --sparky-pink-600: #8c2362;
  --sparky-pink-700: #661a47;

  --sparky-grey-50: #fbfbfb;
  --sparky-grey-100: #f3f3f3;
  --sparky-grey-200: #dbdbdb;
  --sparky-grey-300: #c3c3c3;
  --sparky-grey-400: #888;
  --sparky-grey-500: #5f5f5f;
  --sparky-grey-600: #4b4b4b;
  --sparky-grey-700: #303030;

  /* Forms */
  --color-form-1: #2182bf;
  --color-outline: #2182bfaa;

  /* Charts */
  --color-chart-blue-1: #064861;
  --color-chart-blue-2: #0c8fc1;
  --color-chart-green-1: #85bc04;
  --color-chart-orange-1: #ee9402;
  --color-chart-orange-2: #ee6b01;
  --color-chart-red-1: #cd0000;
  --color-chart-red-2: #490000;
  --color-chart-pink-1: #ca0f88;
  --color-chart-purple-1: #605ca8;

  /* Elevation (Shadows) */
  --elevation-1: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  --elevation-2: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  --elevation-3: 0 6px 12px 0 rgba(0, 0, 0, 0.25);
  --elevation-4: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
}
