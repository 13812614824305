.addBudgetItem {
  display: flex;
  grid-gap: var(--spacing-08);
  align-items: center;

  &.disabled {
    background-color: var(--color-neutral-1);
  }

  &:first-child {
    border-top: none;
  }

  &.selected {
    background-color: rgba(33, 130, 191, 0.05);
  }

  .image {
    width: 7rem;
    object-fit: cover;
    aspect-ratio: 4 / 3;

    &.blur {
      filter: blur(3px);
    }
  }

  .status {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 6rem;
    max-width: 6rem;
    margin-right: var(--spacing-04);
    margin-left: auto;

    .selectInput {
      width: 100%;
    }
  }
}
