@import 'pages/Inventory/InventoryPage/InventoryTable/InventoryTable.module';
@import 'pages/Inventory/Acquisition/AcquisitionTable/AcquisitionTable.module';

.imageCell {
  position: relative;
  height: calc(var(--table-row-height) - 1px);
  aspect-ratio: 1.33;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;

    &.blur {
      filter: blur(3px);
    }
  }

  .countContainer {
    // Overlay on the entire cell, used to calculate size for the imageCount container query
    container-type: size;
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;

    .imageCount {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      background-color: rgba(255, 255, 255, 0.8);

      @container (height < 5rem) {
        visibility: hidden;
      }
    }
  }
}
