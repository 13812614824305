.acquisitionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 24px;

  &.compact {
    padding: 12px 24px;
  }

  .searchBar {
    border-top: none;
    padding: 0;
  }

  .pillNumber {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: var(--border-radius-10);
    background-color: var(--sparky-navy-100);
    color: var(--sparky-blue-500);
  }

  .densityButton {
    height: 2rem !important;
    width: 2rem !important;
  }
}

.densityPopoverContainer {
  display: flex;
  padding: var(--spacing-08);
  flex-direction: column;
  width: fit-content;

  .popoverRow {
    padding: var(--spacing-08);
  }
}

.selectedVin {
  color: var(--sparky-grey-400) !important;
  background-color: var(--sparky-grey-100) !important;
}
