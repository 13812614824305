.tooltip {
  z-index: 2;
}

.option {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.muted {
    opacity: 0.5;
  }

  .itemLabel {
    word-break: break-word;
  }

  .itemCount {
    color: var(--color-neutral-5);
    white-space: nowrap;
    margin-left: var(--spacing-08);
  }

  input[type='radio'] {
    margin: 0 1px;
  }
}
