.popoverContent {
  border-radius: 4px;
  background-color: white;
  box-shadow: var(--sparky-elevation-3);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  position: relative;
  z-index: 200;
  border: 1px solid var(--color-neutral-4);

  &.padded {
    padding: var(--spacing-12);
  }

  &:focus,
  &:focus-visible {
    outline-width: 0 !important;
  }

  .closeButton {
    position: absolute;
    top: var(--spacing-04);
    right: var(--spacing-12);
    cursor: pointer;
  }

  .arrow {
    fill: var(--sparky-brand-white);

    polygon {
      stroke: var(--color-neutral-4);
      // First(color) length 0, Second(null) along bottom as nothing, Last (color) along the top
      stroke-dasharray: 0 30 18;
    }
  }
}
