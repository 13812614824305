.selectDropdownWrapper {
  display: flex;
}

.selectDropdownContent {
  width: fit-content;
  padding: 16px;
}

.selectDropdownHeader {
  padding: 8px;
}

.selectDropdownOption {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: var(--sparky-grey-100);
    border-radius: 4px;
  }
  transition: 0.1s;
}

.dropdownWrapper {
  cursor: pointer;
  padding: 4px;
  margin-bottom: 4px;

  &:hover {
    border-radius: 4px;
    padding: 4px;
    background-color: var(--sparky-navy-100);
  }
  transition: 0.25s;
}

.dropdownLabel {
  cursor: pointer;
}
