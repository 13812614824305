.sidebar {
  z-index: 500;
  position: fixed;
  bottom: 0;

  top: calc(var(--nav-height));
  background-color: #fff;
  color: #fff;
  fill: var(--color-red-2);
  user-select: none;
  white-space: nowrap;
  border-right: 1px solid var(--sparky-grey-200);

  .sidebarContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .logoWrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 6rem;
    padding: 0 var(--spacing-12);

    .logoChevron {
      height: 3rem;
    }
  }

  .spacer {
    grid-area: sidebar;
    position: relative;
  }

  .expandButton {
    height: var(--spacing-32);
    width: var(--spacing-32);
    position: absolute;
    top: var(--spacing-32);
    right: -16px;
    border: 1px solid var(--sparky-grey-200);
    box-shadow: var(--elevation-1);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    cursor: pointer;
    transition: opacity 0.2s;

    &.rotate {
      transform: rotate(180deg);
    }

    &:hover {
      background-color: var(--sparky-brand-red-default);

      svg {
        color: white;
      }
    }
  }

  .linksContainer {
    font-size: var(--font-16);
    justify-content: left;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.pageTitle {
  padding: var(--spacing-32) var(--spacing-24) var(--spacing-16);
}

.sidebarAnalytics {
  padding: 16px;
  color: black;
}
