.buttonGroup {
  display: inline-grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  width: fit-content;
  border-radius: var(--border-radius-08);

  &.boxShadow {
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.25);
  }

  .button {
    border-radius: unset;
    min-width: unset;
    border-color: var(--sparky-navy-200);

    &:not([disabled]) {
      z-index: 1;
    }

    &[disabled] {
      border-color: var(--sparky-grey-300);
      color: var(--sparky-grey-300);
    }

    .content {
      border-radius: unset;
    }

    & + * {
      margin-left: -1px;
    }

    &:first-child {
      border-top-left-radius: var(--border-radius-08);
      border-bottom-left-radius: var(--border-radius-08);

      .content {
        border-top-left-radius: var(--border-radius-08);
        border-bottom-left-radius: var(--border-radius-08);
      }
    }

    &:last-child {
      border-bottom-right-radius: var(--border-radius-08);
      border-top-right-radius: var(--border-radius-08);

      .content {
        border-bottom-right-radius: var(--border-radius-08);
        border-top-right-radius: var(--border-radius-08);
      }
    }

    &[data-state='on'][data-toggleable='true'] {
      color: var(--sparky-text-primary);
      z-index: 2;

      &.toggleable {
        border-color: var(--sparky-navy-400);
        background-color: var(--sparky-navy-100);
      }
    }

    &:focus {
      position: relative;
    }

    &:hover:not([disabled]) {
      z-index: 2;
      border-color: var(--sparky-navy-400);

      &[data-state='on'][data-toggleable='true'] {
        background-color: var(--sparky-navy-200);
      }
    }
  }
}
