.doughnutChartContainer {
  margin-top: var(--spacing-16);
  height: 100%;
  width: 100%;
  display: flex;

  .doughnutChart {
    height: 200px;
    width: 200px;
    position: relative;

    .centeredText {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      z-index: 10;
    }
  }

  .legendContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 200px;

    .legend {
      display: flex;
      flex: 0;
      align-items: center;

      .circle {
        background-color: var(--sparky-blue-400);
        height: var(--spacing-12);
        width: var(--spacing-12);
        border-radius: 100%;
        display: block;
        min-width: var(--spacing-12);
        margin-right: var(--spacing-04);
      }

      .legendText {
        display: flex;
        flex-direction: column;
        row-gap: var(--spacing-04);
      }
    }
  }
}
