.vinPathFilters {
  box-shadow: var(--sparky-boxshadow-left);
  visibility: hidden;
  position: fixed;
  top: 60px;
  bottom: 0;
  right: 0;
  width: 500px;
  border: 1px solid var(--sparky-grey-300);
  background-color: var(--sparky-brand-white);
  z-index: 3;
  transition: ease-in-out 0.2s;

  &.showFilters {
    visibility: visible;
  }

  .vinPathFiltersHeader {
    position: sticky;
    top: 0;
    background-color: var(--sparky-brand-white);
  }

  .vinPathFiltersBody {
    height: calc(100% - 65px - 56px);
    overflow-y: auto;
  }

  .vinPathFiltersFooter {
    position: sticky;
    background-color: var(--sparky-brand-white);
    bottom: 0;
    border-top: 1px solid var(--sparky-grey-300);
  }
}
