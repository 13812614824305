.acquisitionFilters {
  display: none;
  position: relative;
  z-index: 500;

  &.showFilters {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--sparky-grey-100);
    width: 18rem;
    height: calc(100% + 22px);
    box-shadow: var(--sparky-boxshadow-left);
    padding-bottom: 24px;
  }

  .filterHeader {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-12);
    background-color: #fff;
    min-height: 50px;
    z-index: 1;
  }

  .filtersBody {
    overflow-y: auto;
    width: inherit;
  }
}
