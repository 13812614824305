.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
}

.badgeWrapper {
  display: flex;
  justify-content: center;

  .badge {
    display: flex;
    align-items: center;
    justify-content: center;
    border-style: solid;
    border-width: 1px;
    border-color: var(--sparky-brand-white);
    border-radius: 2rem;
    color: var(--sparky-brand-white);
    box-shadow: var(--sparky-elevation-1);
    margin-top: 2px;
  }
}
