.leftLabel,
.rightLabel {
  color: var(--sparky-text-primary);
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;

  &[data-disabled='true'] {
    color: var(--sparky-grey-300);
  }

  &:hover {
    cursor: pointer;
  }
}

.leftLabel {
  padding-right: 8px;
}

.rightLabel {
  padding-left: 8px;
}

.rightHelpText,
.leftHelpText {
  color: var(--sparky-text-secondary);
  padding-left: 47px;

  &[data-disabled='true'] {
    color: var(--sparky-grey-300);
  }
}

.rightHelpText {
  padding-left: 47px;
}
