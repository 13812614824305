.dealerListItem {
  display: flex;
  column-gap: var(--spacing-08);
  padding: var(--spacing-08);
  align-items: center;
  position: relative;

  &.hovered {
    background-color: var(--sparky-grey-100);

    .dealerName {
      color: var(--sparky-navy-400) !important;
    }
  }

  svg {
    min-height: 15px;
    min-width: 15px;
  }

  .dealerName {
    &.hovered {
      max-width: 65%;
    }
  }

  .highlightButton {
    position: absolute;
    right: var(--spacing-08);
    cursor: pointer;
    margin: auto 0;
    top: 0;
    bottom: 0;
  }
}
