.markdownHistoryTable {
  transition: height ease-in-out 0.2s;
  border: 1px solid var(--sparky-grey-100);
  cursor: default;

  .tableHeader {
    padding: 16px 8px;
    color: var(--sparky-text-tertiary);
    font-size: 14px;
    font-weight: 700;
    border: 1px solid var(--sparky-grey-200);

    &.firstColumn {
      color: var(--sparky-text-primary);
      background-color: var(--sparky-grey-100);
    }
  }

  .cell {
    padding: 10px 8px;
    font-size: 14px;
    text-align: left;
    border: 1px solid var(--sparky-grey-200);

    &.firstColumn {
      color: var(--sparky-text-primary);
      background-color: var(--sparky-grey-100);
    }
  }
}
