.selectTrigger {
  display: inline-flex;
  align-items: center;
  color: var(--sparky-text-primary);
  font-weight: 700;
  cursor: pointer;

  &:focus-visible {
    outline: none;
  }
}

.selectIcon {
  display: flex;
  align-items: center;
}

.selectContent {
  background-color: var(--sparky-brand-white);
  padding: var(--spacing-12);
  border: 1px solid #d0d0d0;
  border-radius: var(--border-radius-04);
  box-shadow: var(--sparky-elevation-2);
}

.selectItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  color: var(--sparky-text-primary);
  padding: var(--spacing-08) var(--spacing-12);

  &:hover {
    cursor: pointer;
    background-color: var(--sparky-grey-100);
  }

  &:focus-visible {
    outline: none;
  }
}

.selectItem[data-state='checked'] {
  color: var(--sparky-navy-400);
  font-weight: 700;
}
