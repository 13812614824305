.radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  --radio-width: 24px;
  --offset: 0;

  position: relative;
  height: var(--radio-width);
  width: var(--radio-width);
  margin: 0;
  border-width: 1.5px;
  border-style: solid;
  border-radius: 50%;
  border-color: var(--border-active-01);
  outline: none;
  flex-shrink: 0;
  cursor: pointer;

  &:hover:not([disabled], .active, .noHover) {
    background-color: var(--bg-hover);
    border-color: var(--border-strong-01);
    box-shadow: 0 0 0 calc(var(--radio-width) * 0.5) var(---bg-hover);

    &.smallHover {
      box-shadow: 0 0 0 4px var(--bg-hover);
    }
  }

  &:focus {
    box-shadow: 0 0 0 1px var(--sparky-brand-white), 0 0 0 3px var(--sparky-blue-300);

    &:hover:not([disabled], .active) {
      background-color: var(--bg-hover);
      border-width: 2px;
      border-color: var(--border-focused);
      box-shadow: 0 0 0 1px var(--bg-hover), 0 0 0 3px var(--sparky-blue-300),
        0 0 0 calc(var(--radio-width) * 0.5) var(--bg-hover);

      &.smallHover {
        box-shadow: 0 0 0 1px var(--bg-hover), 0 0 0 3px var(--sparky-blue-300),
          0 0 0 4px var(--bg-hover);
      }
    }
  }

  &:after {
    position: absolute;
    top: var(--offset);
    bottom: var(--offset);
    left: var(--offset);
    right: var(--offset);

    content: ' ';
    background-color: var(--bg);
    border-radius: 50%;

    transform: scale(0);
    transition: transform 0.2s;
  }

  &.active:not([disabled]) {
    border-color: var(--bg-selected);

    &:after {
      background-color: var(--bg-selected);
      transform: scale(0.57);
    }
  }

  &.disabled {
    border-color: var(--border-subtle-01);
    cursor: default;

    &.active {
      border-color: var(--bg-selected-disabled);

      &:after {
        background-color: var(--bg-selected-disabled);
        transform: scale(0.57);
      }
    }
  }
}
