.dot {
  cursor: pointer;
  stroke-opacity: 0.5;
  stroke-width: 2;
  fill: var(--sparky-blue-400);
  stroke: var(--sparky-blue-200);
  transition: all ease-in-out 0.2s;

  &.currentVin {
    fill: var(--sparky-red-400);
    stroke: var(--sparky-red-200);
  }

  &:hover {
    stroke-opacity: 1;
    fill: var(--sparky-brand-white);
    stroke: var(--sparky-blue-400);

    &.currentVin {
      stroke: var(--sparky-red-400);
    }
  }

  &:active {
    stroke-opacity: 1;
    stroke-width: 4;
  }
}
