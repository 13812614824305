.chatBody {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 1000px;
  max-width: 80vw;
  margin-top: 42px;
  position: relative;

  .innerChatBody {
    height: 100%;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 350px);
    row-gap: 24px;
    padding: 4px;
    overflow-y: auto;
  }

  .chatOverlay {
    display: flex;
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 200;
    background: linear-gradient(0deg, rgba(251, 251, 251, 0) 0%, rgba(251, 251, 251, 0.8) 100%);
  }
}

.prompt {
  background-color: var(--sparky-navy-400);
  padding: 12px 16px;
  display: flex;
  column-gap: 10px;
  border-radius: 24px;
  max-width: calc(100% - 56px);
}

.userIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: white;
  height: 24px;
  width: 24px;

  span {
    color: black;
    font-weight: 500;
    font-size: 12px;
  }
}

.lexIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: white;
  height: 24px;
  width: 24px;
  position: relative;

  @keyframes pulse {
    0% {
      transform: scale(0);
      opacity: 0.05;
    }

    40% {
      transform: scale(0.5);
      opacity: 0.1;
    }

    80% {
      transform: scale(0.9);
    }

    100% {
      transform: scale(1);
      opacity: 0.05;
    }
  }

  .circle {
    width: 24px;
    height: 24px;
    background: var(--sparky-red-400);
    position: absolute;
    border-radius: 100%;
  }

  .circle:first-child {
    opacity: 0;
    animation: 4s pulse infinite;
  }

  .circle:nth-child(2) {
    opacity: 0;
    animation: 4s pulse infinite;
    animation-delay: 1s;
  }

  .circle:nth-child(3) {
    opacity: 0;
    animation: 3s pulse infinite;
    animation-delay: 3s;
  }

  @keyframes surge {
    0% {
      transform: scale(0.75);
    }

    50% {
      transform: scale(0.9);
    }

    100% {
      transform: scale(0.75);
    }
  }

  svg {
    transform: scale(0.75);
    // animation: 3s surge infinite;
  }
}

.followUp {
  width: fit-content !important;
}

.response {
  background-color: var(--sparky-grey-100);
  padding: 12px 16px;
  display: flex;
  column-gap: 16px;
  border-radius: 24px;
  align-items: flex-start;
  max-width: calc(100% - 56px);

  .markdownContainer {
    display: flex;
    flex-direction: column;
    overflow-x: auto;

    // css for the markdown injections in the chat response

    strong {
      font-weight: 600;
    }

    p {
      padding: 4px 0;
      font-weight: 400;
    }

    table {
      border-collapse: collapse;
      background-color: #fff;
      margin: 12px 0;

      th,
      td {
        padding: 6px;
        border: 1px solid var(--sparky-grey-300);
        font-size: 14px;
      }

      tr {
        background-color: var(--sparky-grey-100);
      }

      tr:nth-child(2n -1) {
        background-color: var(--sparky-grey-200);
      }

      thead {
        th {
          font-weight: 600;
        }

        tr {
          background-color: var(--sparky-grey-100) !important;
        }
      }
    }

    ol {
      list-style: decimal;
      padding-left: 2rem;
      margin: 4px 0;
    }

    ul {
      margin: 8px 0;

      li {
        list-style-type: disc;
      }
    }
  }
}

.animationContainer {
  padding-top: 2px;
  display: flex;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

@keyframes blink2 {
  75% {
    opacity: 0;
  }
}

.loaderDot:nth-child(2) {
  animation: 1s blink infinite;
}

.loaderDot:nth-child(3) {
  animation: 1s blink2 infinite;
}
