.filter {
  padding: var(--spacing-12) 0;
  margin: 0 var(--spacing-12);

  &.search {
    padding: 0;
    margin: 0;
  }

  .filterHeader {
    display: grid;
    grid-template-columns: 1fr auto auto;
    justify-content: space-between;
    align-items: center;
    grid-gap: var(--spacing-08);

    .contextButton {
      font-size: var(--font-14);
    }
  }

  &.filter {
    border-top: 1px solid var(--sparky-grey-100);
  }
}
