*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
p,
ul,
ol {
  margin: 0;
}

ul,
ol {
  list-style: none;
}

input,
button,
textarea,
select {
  font: inherit;
}

a,
button {
  text-decoration: none;
  border: 0;
  background-color: unset;
  padding: unset;
  margin: unset;
}
