@import 'Sparky/styles/layouts';

.dealerSelect {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--sparky-brand-white);
  width: 100%;
  max-height: 42rem;

  .dealerCategoryContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 8px 16px 16px;
  }

  .search {
    width: 100%;

    .closeButton {
      display: inline-flex;
      align-items: center;
      width: fit-content;
      transition: all ease-in-out 0.2s;

      &:hover:not([disabled]) {
        color: var(--sparky-red-400);
        background-color: var(--sparky-navy-100);
        border-radius: var(--border-radius-04);
        cursor: pointer;
      }
    }
  }
}

.backIcon {
  height: auto;
  width: auto;
  box-sizing: border-box;
  padding: 6px;
  margin: 6px;

  &:hover {
    background-color: var(--sparky-grey-100);
    cursor: pointer;
    border-radius: 8px;
  }
}

.dealerList {
  height: 100%;
}

.dealerHeader {
  padding: 16px 20px 8px;
}

.list {
  display: flex;
  flex: 1;
  flex-grow: 0;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 12px;
}
