.priceScenarios {
  width: 100%;
  display: grid;
  grid-template-columns: auto repeat(3, 1fr);
  align-items: center;
  justify-content: flex-start;
  cursor: default;

  .arrow {
    margin: auto;
  }

  .divider {
    border-top: 1px solid var(--sparky-text-secondary);
  }
}
