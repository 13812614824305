.interactiveRow {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  min-height: 39px;
  gap: 16px;

  &.largeSize {
    min-height: 45px;
  }

  .hoverContainer {
    display: flex;
    padding: 6.5px 8px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    gap: 16px;
    width: 100%;
    flex: 1 0 0;

    &.hoverWithBorder:hover {
      background: var(--bg-hover, rgba(13, 43, 65, 0.1));
    }

    &.hoverWithoutBorder:hover {
      border-radius: 6px;
      background: var(--bg-hover);
    }

    &.border {
      padding: 10.5px 8px;
      border-top: 1px solid var(--border-subtle-01);
      border-bottom: 1px solid var(--border-subtle-01);
    }

    .iconContainer {
      height: 16px;
      width: 16px;

      &.largeIconContainer {
        height: 20px;
        width: 20px;
      }
    }

    .textHorizontalContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      &.textVerticalContainer {
        display: flex;
        gap: 8px;
        flex-direction: column;
      }

      .label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
