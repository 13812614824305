.dropdownButtonWrapper {
  display: flex;
}

.dropdownButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 4px;

  &:hover {
    cursor: pointer;
  }
}

.dropdownPopover {
  top: 5px;
  z-index: 200;
}

.active {
  color: var(--sparky-red-400);
  font-weight: 700;
}
