.vinPathSearchBox {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  margin-top: 24px;
  width: 1100px;
  max-width: 1100px;

  .tootipIcon {
    display: inline-block;
  }
}
