.addBudgetTable {
  position: relative;
  overflow: auto;

  .theader {
    background-color: var(--sparky-brand-white);
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
  }

  .tableRow {
    border-bottom: 1px solid var(--sparky-grey-100);

    .tableHeader {
      height: 100%;
      padding: var(--spacing-08);
      color: var(--sparky-text-tertiary);
    }
  }
}

.imgCell {
  padding: var(--spacing-08) 0;
  display: flex;
}

.noPadding {
  padding: 0;
}

.tableCell {
  padding: var(--spacing-08);
}

.image {
  width: 7rem;
  object-fit: cover;
  aspect-ratio: 4 / 3;
}
