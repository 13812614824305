.segmentsTileIcon {
  color: white;
  border-radius: 50%;
  padding: 2px;
  height: 15px;
  width: 15px;

  &Green {
    background-color: var(--sparky-green-400);
  }

  &Orange {
    background-color: var(--sparky-orange-400);
  }

  &Red {
    background-color: var(--sparky-red-400);
  }
}
