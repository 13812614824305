[data-reach-dialog-overlay] {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(11, 32, 59, 0.5); // --color-text-primary, 50% opacity
  padding: var(--spacing-16);
}

[data-reach-dialog-content] {
  display: flex;
  flex-direction: column;
  max-height: 100%;

  background-color: #fff;
  border-radius: var(--border-radius);
  box-shadow: var(--elevation-3);
  outline: none;
  overflow: hidden;

  .modal__content {
    overflow: auto;
  }
}
