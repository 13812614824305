.legend {
  width: 100%;
  padding: 0 var(--spacing-16) var(--spacing-16);

  .legendItemRow {
    display: flex;
    column-gap: var(--spacing-04);
    align-items: center;
    padding: 0 0 var(--spacing-08) 0;
    margin-bottom: var(--spacing-08);

    .legendIcon {
      display: flex;
    }

    svg {
      transform: scale(0.8);
    }
  }

  .gradiantBar {
    width: 100%;
    height: var(--spacing-12);
    margin-top: var(--spacing-08);
  }

  .minMaxRow {
    display: flex;
    flex-direction: 1;
    justify-content: space-between;
    margin-top: var(--spacing-08);
  }
}

.segmentSelectorOverlay {
  position: absolute;
  top: 140px;
  left: 36px;
  z-index: 1;
  box-shadow: var(--elevation-1);

  .segmentPanel {
    padding: var(--spacing-32) var(--spacing-24) var(--spacing-16);
    display: flex;
    flex-direction: column;
    width: 333px;
    row-gap: var(--spacing-12);
    position: relative;
    border: none;
    box-shadow: var(--elevation-1);

    .currentSegementRow {
      display: flex;
      column-gap: var(--spacing-08);
    }

    .togglePanelButton {
      position: absolute;
      right: -28px;
      top: var(--spacing-24);
    }

    .moreDataRow {
      display: flex;
      justify-content: flex-end;

      svg {
        margin-left: var(--spacing-08);
      }
    }
  }
}

.accordian {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-08) 0;
  border-top: 1px solid var(--sparky-grey-200);
  border-bottom: 1px solid var(--sparky-grey-200);

  .accordianHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-04) var(--spacing-08) var(--spacing-08);

    .accordianTitle {
      display: flex;
      align-items: center;
    }

    .accordianControl {
      cursor: pointer;
      transform: rotate(90deg);

      &.closed {
        transform: rotate(270deg);
      }
    }
  }
}

.segmentPopoverConent {
  z-index: 1000;
}
