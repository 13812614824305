.link,
.subLink {
  display: flex;
  align-items: center;

  &.link {
    padding: var(--spacing-16) var(--spacing-24);
  }

  &.groupHeader {
    padding: var(--spacing-08) 0 var(--spacing-08) var(--spacing-24);
    margin: 0 var(--spacing-16);
    width: 80%;
  }

  &.subLink {
    padding: var(--spacing-08) var(--spacing-16) var(--spacing-08);
    margin: 0 var(--spacing-24);
    transition: all ease-in-out 0.3s;

    &:hover {
      background-color: var(--sparky-grey-100);
    }
  }

  &.active {
    background-color: var(--sparky-grey-100);
  }

  span {
    //affects all icons on link/group header
    display: flex;
    align-items: center;
  }

  .linkLabel {
    margin: 0;
  }

  .arrow {
    margin-left: auto;
  }

  .chevronWrap {
    display: flex;
    align-items: center;
    width: 0;
    height: 100%;
    position: relative;

    svg {
      position: absolute;
      transform: scale(1);
      left: -15px;
    }
  }
}

.expandedChildContainer {
  margin-left: 12px;
}
