.noData {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.panel {
  width: 100%;
  padding: 16px;

  .source {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 12px;
    flex-direction: column;

    .chartTitle {
      display: flex;
      justify-content: space-between;
      z-index: 2;

      .selectPrefix {
        padding-right: 4px;
      }
    }

    .sourceItems {
      height: calc(100% - 37px);
      display: flex;
      flex-direction: column;
      gap: 12px;
      overflow-y: auto;

      .sourceItem {
        display: flex;
        justify-content: space-between;
        gap: 12px;
        flex-direction: column;

        .sourceTitle {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .progressBar {
          width: 100%;
          height: 2px;
          position: relative;

          &:after {
            content: '';
            position: absolute;
            height: 1px;
            bottom: 0;
            right: 0;
            left: 0;
            z-index: 0;
            background-color: var(--sparky-grey-200);
          }

          span {
            position: absolute;
            height: 2px;
            background-color: var(--sparky-blue-400);
            display: flex;
            border-top-left-radius: 1px;
            border-bottom-left-radius: 1px;
            z-index: 1;
            bottom: 0;
            left: 0;
          }
        }
      }
    }
  }
}
