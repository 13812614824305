.DealerFavorites {
  display: flex;
}

.DealerNoFavorites {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 12px;
  padding-block: 24px;
  max-width: 280px;
  margin-inline: auto;
  white-space: wrap;
}

.dealerHeader {
  padding: 16px 16px 0;
}

.dealerList {
  height: auto;
  overflow-y: auto;
  padding: 16px;
}
