.fullScreenMap {
  height: 100%;
  width: 100%;
}

.marketopportunity {
  display: flex;
  flex: 1;
  justify-content: center;
  background-color: var(--sparky-background);

  .segmentsPanelSwitch {
    display: flex;
    align-items: center;

    .filterText {
      color: var(--sparky-blue-500);
      margin-right: var(--spacing-08);
      cursor: default;
    }

    .divider {
      cursor: default;

      &::selection {
        background: transparent;
      }
    }
  }

  .pageContainer {
    display: flex;
    width: 100%;
    height: var(--content-height);
    flex-direction: column;

    .titleRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: var(--spacing-16);
    }

    .panelContainer {
      margin: 0 auto;
      padding: var(--gap-md) 48px;
      max-width: 1650px;
      width: 100%;
    }

    .tabs {
      width: 100%;
    }

    .tabList {
      padding: var(--spacing-16) var(--spacing-16) 0;
    }

    .content {
      padding: var(--spacing-16) 0;
    }

    .messageContainer {
      margin: var(--spacing-24) var(--spacing-16) 0;
    }

    .topRow {
      padding-left: var(--spacing-16);
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      margin-top: var(--spacing-24);
      column-gap: var(--spacing-24);
      border-bottom: 1px solid var(--sparky-grey-200);

      .col {
        display: flex;
        flex-direction: column;
        width: 50%;
        height: 520px;
      }

      .mapContainer {
        height: 520px;
        display: flex;
        flex: 1;
        min-width: 500px;
        max-width: 100%;
        border: 1px solid var(--sparky-grey-200);

        .popup {
          padding: var(--spacing-04);
        }

        .popupContent {
          padding: var(--spacing-04);
        }
      }

      .defaultMessageContainer {
        margin: var(--spacing-24) var(--spacing-16) var(--spacing-24) 0;
        width: 100%;
      }

      @mixin responsiveTopRow {
        flex-direction: column;
        row-gap: var(--spacing-16);
        height: 1000px;
        padding: 0 var(--spacing-16);

        .col {
          height: 420px;
          width: 100%;
        }

        .mapContainer {
          height: 100%;
          width: 100%;
        }

        .defaultMessageContainer {
          margin-top: var(--spacing-08);
        }
      }

      @media screen and (max-width: 1750px) {
        &.sidebarOpen.segmentPanelOpen {
          @include responsiveTopRow;
        }
      }

      @media screen and (max-width: 1350px) {
        &.sidebarOpen {
          @include responsiveTopRow;
        }
      }

      @media screen and (max-width: 1100px) {
        @include responsiveTopRow;
      }
    }

    .colTitle {
      margin-bottom: var(--spacing-16);
      display: flex;
      align-items: center;
      column-gap: var(--spacing-04);

      span {
        display: flex;
      }

      &.noMargin {
        margin-bottom: var(--spacing-04);
      }
    }

    .bottomRowTitle {
      padding: 0 var(--spacing-16);
      margin-top: var(--spacing-24);
    }

    .bottomRow {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      margin-top: var(--spacing-24);
      column-gap: var(--spacing-32);
      padding: 0 var(--spacing-16);
      row-gap: var(--spacing-16);

      @media screen and (min-width: 1599px) {
        column-gap: var(--spacing-64);
        padding: 0 var(--spacing-32);
      }

      .col {
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 280px;
        padding: 0 16px;
      }

      @mixin responsiveBottomRow {
        grid-template-columns: 1fr 1fr;
      }

      @media screen and (max-width: 1500px) {
        &.sidebarOpen.segmentPanelOpen {
          @include responsiveBottomRow;
        }
      }

      @media screen and (max-width: 1300px) {
        &.sidebarOpen {
          @include responsiveBottomRow;
        }
      }

      @media screen and (max-width: 1100px) {
        @include responsiveBottomRow;
      }
    }

    .lineChartContainer {
      display: flex;
      flex: 1;
    }

    .barChartContainer {
      display: flex;
      flex: 1;
      margin-top: var(--spacing-08);
    }

    .radioButtons {
      display: flex;
      flex-direction: column;
      row-gap: var(--spacing-16);
      margin-bottom: var(--spacing-24);

      span {
        display: flex;
      }
    }

    .demandSubText {
      margin-left: var(--spacing-04);
      font-size: 12px;
    }
  }
}
