@import '../../../../Sparky/styles/mixins.scss';

.dealerSelectList {
  position: relative;
  height: 100%;

  .error,
  .placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 533px;
    padding: 16px;
  }
}

.dealerHeader {
  padding: 8px;
}

.wrapper {
  width: 100%;
  padding: 0 12px 0;
}
