.navbar {
  position: sticky;
  top: var(--dealer-select-bar-height);
  left: 0;
  right: 0;
  z-index: 101;

  display: flex;
  align-items: center;
  gap: var(--spacing-12);
  padding: var(--spacing-16) var(--spacing-24);
  background-color: #fff;
  color: var(--sparky-text-primary);
  user-select: none;
  white-space: nowrap;
  height: var(--nav-bar-height);
  border-bottom: 1px solid var(--sparky-navy-100);

  &Settings {
    display: flex;
    flex-direction: column;
  }

  .logoWrapper {
    display: flex;
    gap: var(--spacing-12);
    align-items: center;
    justify-content: center;
    margin-right: 8px;

    .logo {
      height: 2rem;
    }
  }

  .linksContainer {
    display: flex;
  }

  .marketMapsLink {
    display: flex;
    align-items: center;
  }

  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.75rem;
    height: 2.75rem;
    border-style: solid;
    border-width: var(--spacing-02);
    border-color: var(--sparky-brand-white);
    border-radius: 2rem;
    background-color: var(--sparky-brand-red-default);
    color: var(--sparky-brand-white);
    box-shadow: var(--sparky-elevation-1);
  }

  .chatButton {
    flex-shrink: 0;
    cursor: pointer;

    svg {
      transform: scale(1.5);
    }

    &:hover {
      background-color: #fff1 !important;
      border-color: #fff2 !important;
    }
  }
}

hr {
  color: var(--sparky-grey-200);
  margin: var(--spacing-04) 0;
}

.popupContainer {
  padding: var(--spacing-16);
}

.popover {
  z-index: 999;
}

.userProfileList {
  display: flex;
  color: #000;
  padding: var(--spacing-12);
  flex-shrink: 0;
  cursor: pointer;
  align-items: center;

  &:hover {
    background-color: var(--sparky-grey-100);
  }

  &.active {
    font-weight: bold;
  }

  &.disabled {
    cursor: default;

    &:hover {
      background-color: white;
    }
  }

  svg {
    transform: scale(1.2);
    margin-right: var(--spacing-08);
  }

  .icon {
    display: flex;
    align-items: center;
  }
}

.settingsDivider {
  border-color: var(--sparky-grey-200) !important;
}

.settingsCacheRefresh {
  padding: 16px !important;
}

.settingsSpinner {
  width: 16px;
  height: 16px;
  animation-duration: 1s;
}
