.addColumnsContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-24);
  padding: var(--spacing-24);
}

.inventoryTable {
  width: 100%;
  height: 100%;
  border-top: 1px solid var(--sparky-grey-100);
  overflow-x: auto;
  overflow-y: hidden;

  &.withSidebar {
    width: calc(100% - 18rem);
  }

  .inventoryTableWrapper {
    position: relative;
    display: flex;
    align-items: flex-start;
    height: calc(100% - 3.5rem);
    overflow: auto;
    font-size: 14px;
    //mask: linear-gradient(
    //  to right,
    //  rgba(0, 0, 0, 1) 0,
    //  rgba(0, 0, 0, 1) 99%,
    //  rgba(0, 0, 0, 0.25) 99.5%,
    //  rgba(0, 0, 0, 0) 100%
    //);

    thead {
      position: sticky;
      top: 0;
      z-index: 1;
    }

    .frozenTable {
      background-color: #fff;
      position: sticky;
      left: 0;
      z-index: 1;
      margin-right: -1px;
      width: unset;

      .selectAll {
        padding: var(--spacing-08) var(--spacing-16);
        height: 3.75rem;
        background-color: #fff;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .frozenTableColumnHeader {
        white-space: nowrap;
        background-color: #fff;
        height: 3.75rem;
        color: var(--sparky-grey-400);
        z-index: 1;

        &:hover {
          cursor: pointer;
          background-color: var(--sparky-grey-100);
        }

        &.divider {
          border-right: 2px solid var(--sparky-grey-400);
        }
      }

      .frozenTableRow {
        cursor: pointer;
        height: var(--table-row-height);
        background-color: rgba(231, 240, 254, 0.5);

        &.frozenTableSelected {
          background-color: var(--sparky-blue-100);
        }

        &.frozenTableHover {
          box-shadow: var(--elevation-1);
        }

        .cell,
        .budgetCell {
          height: var(--table-row-height);
          border: 1px solid var(--sparky-grey-100);
          padding: var(--spacing-04);

          &.checkbox {
            border-left: none;
            background-color: var(--sparky-brand-white);
            cursor: default;
          }

          &.selectCell {
            background-color: var(--sparky-grey-200);
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &.showBudgetSelectCell {
            background-color: var(--sparky-brand-white);
          }

          &.divider {
            border-right: 2px solid var(--sparky-grey-400);
          }

          .hasExistingAddBudget {
            background-color: var(--sparky-navy-300);
          }
        }

        .budgetCell {
          background-color: var(--sparky-brand-white);

          &.selectActiveBudgetCell {
            background-color: var(--sparky-grey-200);
          }
        }
      }
    }

    .mainTable {
      background-color: #fff;
      position: relative;
      z-index: 0;
      width: unset;

      .mainTableColumnHeader {
        white-space: nowrap;
        height: 3.75rem;
        background-color: #fff;
        color: var(--sparky-grey-400);
        z-index: 1;

        &:hover {
          cursor: pointer;
          background-color: var(--sparky-grey-100);
        }
      }
    }

    .mainTableRow {
      height: var(--table-row-height);
      cursor: pointer;

      &.mainTableSelected {
        background-color: var(--sparky-grey-200);
      }

      &.mainTableHover {
        box-shadow: var(--elevation-1);
      }

      .cell {
        height: var(--table-row-height);
        border: 1px solid var(--sparky-grey-100);
        padding: var(--spacing-04);
      }
    }
  }

  .tableFooter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: var(--spacing-08);
    gap: var(--spacing-08);
    border-top: 1px solid var(--sparky-grey-100);
    border-bottom: 1px solid var(--sparky-grey-100);

    .inventoryTablePagination,
    .budgetTablePagination {
      border-top: none;
      border-bottom: none;
    }

    .budgetTablePagination {
      margin-right: 30rem;
    }
  }

  .noPadding {
    height: var(--table-row-height);
    padding: 0 !important;
  }

  .budgetHeader,
  .budgetCellRender {
    display: flex;
    align-items: center;
    gap: var(--spacing-02);
  }

  .budgetCellRender {
    justify-content: flex-end;
  }

  .riskScore {
    padding: var(--spacing-08) var(--spacing-24);

    > div {
      margin: 0 auto;
    }
  }

  .riskScoreIconRed {
    border-radius: 50%;
    background-color: var(--sparky-red-400);
  }

  .riskScoreIconOrange {
    border-radius: 50%;
    background-color: var(--sparky-orange-400);
  }

  .riskScoreIconGreen {
    border-radius: 50%;
    background-color: var(--sparky-green-400);
  }
}

.totalLeadsByStatus {
  display: flex;
  gap: var(--spacing-04);
  margin-right: auto;
}

.tablePill {
  &Green {
    border-radius: var(--border-radius-16);
    padding: var(--spacing-08);
    background-color: var(--sparky-green-100);
    color: var(--sparky-green-500);
    font-weight: 500;
    margin-right: var(--spacing-12);
  }

  &Grey {
    border-radius: var(--border-radius-16);
    padding: var(--spacing-08);
    background-color: var(--sparky-grey-100);
    color: var(--sparky-grey-500);
    font-weight: 500;
    margin-right: var(--spacing-12);
  }
}
