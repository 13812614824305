.tabs {
  --orientation: column;

  display: inline-flex;
  flex-direction: var(--orientation);
  font-size: 16px;

  &.smallFontSize {
    font-size: 12px;
  }
}

.tabsList {
  display: inline-grid;
  grid-auto-flow: var(--orientation);
  grid-auto-columns: 1fr;
}

.tab {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-04) var(--spacing-08) 3px;
  outline: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--border-subtle-01);
  cursor: pointer;

  .tabText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 400;
    visibility: visible;
    border-radius: var(--border-radius-04);
    padding: var(--spacing-04) var(--spacing-08);
    white-space: nowrap;
  }

  .tabTextHover {
    border-radius: var(--border-radius-04);
    padding: var(--spacing-04) var(--spacing-08);
    font-weight: 700;
    visibility: hidden;
    white-space: nowrap;
  }

  &:hover:not([disabled]) {
    .tabText {
      visibility: hidden;
    }

    .tabTextHover {
      visibility: visible;
    }
  }

  &:focus-visible:not([disabled]) {
    .tabText {
      padding: var(--spacing-02) 6px;
      border: 2px solid var(--border-focused);
    }

    .tabTextHover {
      padding: var(--spacing-02) 6px;
      border: 2px solid var(--border-focused);
    }
  }

  &[data-state='active'] {
    padding-bottom: 2px;
    border-bottom-width: 2px;
    border-bottom-color: var(--border-active-02);

    .tabText {
      visibility: hidden;
    }

    .tabTextHover {
      visibility: visible;
    }
  }

  &:disabled {
    color: var(--text-disabled);

    &[data-state='active'] {
      border-bottom-color: var(--border-active-disabled-02);
    }
  }
}

.tabContent {
  outline: none;
}
