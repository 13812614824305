.searchOptions {
  padding: var(--spacing-12);
}

.searchInput {
  width: 100%;
}

.closeButton {
  display: inline-flex;
  align-items: center;
  width: fit-content;
  transition: all ease-in-out 0.2s;

  &:hover:not([disabled]) {
    color: var(--sparky-red-400);
    background-color: var(--sparky-navy-100);
    border-radius: var(--border-radius-04);
    cursor: pointer;
  }
}
