.vinDetailHeader {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--sparky-brand-white);

  &.smallVinDetailHeader {
    box-shadow: var(--elevation-2);
  }

  .headerVinTitle {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .closeBtn {
    margin-left: auto;

    &.smallCloseBtn {
      margin-left: 0;
    }
  }

  .infoSummary {
    overflow: hidden;
    transition: all ease-in-out 0.2s;
    border-bottom: 1px solid var(--sparky-grey-100);

    &.hideInfoSummary {
      height: 0;
    }

    .image {
      height: 112px;
      margin: 0 0 auto;
      aspect-ratio: 4 / 3;
      transition: all ease-in-out 0.2s;

      &.smallImage {
        width: 60px;
        min-width: 60px;
      }

      .blur {
        filter: blur(3px);
      }
    }

    .titleContainer {
      flex-wrap: nowrap;

      .viewVdpBtn {
        padding: 0;
        height: 24px;
      }
    }
  }
}
