.chartLegend {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 12px;
  gap: 16px;

  .legend {
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 4px;

    .circle {
      height: 8px;
      width: 8px;
      border-radius: 4px;
      border-width: 1px;
      border-style: solid;
    }

    .rect {
      height: 4px;
      width: 12px;
    }

    .dotLine {
      border-bottom: 3px dotted;
      width: 12px;
    }
  }
}
