.responseWrapper {
  width: 100%;

  .feedbackButtons {
    display: flex;
    gap: var(--spacing-04);
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin: var(--spacing-08) 0 var(--spacing-12);

    .button {
      &:disabled {
        opacity: 1;

        &:hover {
          border-color: transparent;
        }
      }
    }
  }
}

.chatBubble {
  position: relative;

  width: 100%;
  padding: var(--spacing-16);

  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: var(--border-radius);
  background-color: #fff;

  table {
    border-collapse: collapse;
    background-color: #fff;
    margin: 12px 0;

    th,
    td {
      padding: 8px;
      border: 1px solid var(--sparky-grey-300);
    }

    tr:nth-child(2n -1) {
      background-color: var(--sparky-grey-100);
    }

    thead {
      tr {
        background-color: #fff !important;
      }
    }
  }

  p {
    padding: 4px 0;
  }

  ol {
    list-style: decimal;
    padding-left: 2rem;
    margin: 4px 0;
  }

  ul {
    margin: 4px 0;

    li {
      list-style-type: disc;
    }
  }

  .divider {
    margin: var(--spacing-12) 0 var(--spacing-16);
  }

  .thinking {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .cursor {
    content: '';
    width: 1ch;
    margin-left: var(--spacing-04);
    background-color: currentcolor;
    border-radius: 1px;
    display: inline-block;
    height: 1em;
    transform: translateY(2px);
    animation: blink-animation 1.5s steps(5, start) infinite;
  }

  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }

  @-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
}
