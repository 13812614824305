.soldInventorySummary {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: var(--spacing-16);
  height: 0;
  overflow: hidden;
  transition: height ease-in-out 0.2s;

  &.showSoldInventorySummary {
    height: 198px;
    padding: var(--spacing-16) var(--spacing-16) var(--spacing-08);
    flex-shrink: 0;
  }
}
