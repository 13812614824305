.SwitchRoot {
  /* reset */
  all: unset;
  width: 36px;
  height: 20px;
  background-color: var(--switch-off);
  border-radius: 9999px;
  border-width: 1.5px;
  border-style: solid;
  border-color: var(--switch-off-border);
  color: var(--switch-off);
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:hover {
    cursor: pointer;
  }

  &[data-state='checked'] {
    &:disabled {
      color: var(--switch-on-disabled);
      border-color: var(--switch-on-disabled);
      background-color: var(--switch-on-disabled);
    }

    &:not([disabled]) {
      color: var(--sparky-red-400);
      border-color: var(--switch-on);
      background-color: var(--switch-on);

      &:hover {
        border-color: var(--switch-on-hover);
        background-color: var(--switch-on-hover);
      }
    }
  }

  &:disabled {
    border-color: var(--switch-off-disabled-border);
    background-color: var(--switch-off-disabled);
  }

  &:not([disabled]) {
    &:focus {
      box-shadow: 0 0 0 1px var(--sparky-brand-white), 0 0 0 3px var(--border-focused);
    }

    &:hover {
      background-color: var(--switch-off-hover);

      .SwitchThumb {
        opacity: 80%;
      }
    }
  }

  .SwitchThumb {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 9999px;
    transition: transform 100ms;
    transform: translateX(0);
    will-change: transform;

    &[data-state='checked'] {
      transform: translateX(16px);
    }
  }
}
