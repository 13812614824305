@import '../../Sparky/styles/layouts.scss';

.container {
  display: flex;
  flex-direction: column;
  height: var(--content-height);
  align-items: center;
  justify-content: center;
}

.text {
  margin: var(--spacing-32) 0 var(--spacing-16) 0;
}
