.vdpSection {
  padding: 0 12px;

  &.openState {
    background-color: var(--sparky-background);
  }

  .panel {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    padding: 16px;
  }
}
