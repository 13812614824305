.acquisition {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .pageHeader {
    padding: 36px 24px;

    &.compact {
      padding: 24px;
    }
  }

  .acquisitionContent {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 24px 24px;
    transition: all ease-in-out 0.2s;
    overflow: auto;
    overflow-y: hidden;

    &.compact {
      padding: 0 12px 12px;
    }
  }
}
