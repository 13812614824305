.brainChatHeader {
  display: flex;
  width: 370px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 30px;
  margin-top: 120px;
  margin-bottom: 24px;

  .brainCircle {
    display: flex;
    height: 108px;
    width: 108px;
    border-radius: 100%;
    border: 4px solid #ececec;
    align-items: center;
    justify-content: center;
  }
}
