.chatHeader {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--spacing-04);
  width: 100%;

  padding: var(--spacing-04);
  border-bottom: 1px solid var(--color-neutral-4);
  z-index: 1;

  .brainLogo {
    svg {
      height: 2rem;
      width: 2rem;
    }
  }

  .betaTag {
    padding: var(--spacing-04) var(--spacing-08);
    border: 1px solid var(--sparky-red-400);
    border-radius: 99px;
    color: var(--sparky-red-400);
    font-size: var(--font-12);
    font-weight: bold;
    display: inline-block;
  }
}
