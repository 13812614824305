.vinPathTableHeader {
  position: sticky;
  top: 0;
  background-color: var(--sparky-background);
  z-index: 1;
  border-bottom: 1px solid var(--sparky-grey-300);

  .pillNumber {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: var(--border-radius-10);
    background-color: var(--sparky-navy-100);
    color: var(--sparky-blue-500);
  }

  .checkboxLabel {
    padding-left: 12px;
    margin-right: auto;

    &.hoverCheckboxLabel {
      color: var(--sparky-text-blue);
      font-size: 20px;
      font-weight: 700;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .markdownInfo {
    border-right: 1px solid var(--sparky-grey-200);
  }
}
