.errorFallback {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  line-height: 1.5;

  .details {
    max-width: 20rem;
    margin-top: var(--gap-md);
  }
}
