.headerContainer {
  height: 100%;

  .columnContent {
    height: 100%;
    display: flex;
    align-items: center;
    padding: var(--spacing-08);
    gap: var(--spacing-04);

    .inventoryHeader {
      border-bottom: 1px dashed;
      padding-bottom: 2px;
    }

    .sortArrow {
      opacity: 0;
      color: var(--sparky-blue-400);

      &.asc {
        opacity: 1;
      }

      &.desc {
        opacity: 1;
        transform: rotateX(180deg);
      }
    }
  }
}

.sortListPopover {
  z-index: 1;

  .sortList {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: normal;

    .sortItem {
      display: flex;
      align-items: center;
      text-align: left;
      gap: var(--spacing-08);
      width: 100%;
      padding: var(--spacing-08) var(--spacing-12);

      .checkedIcon {
        margin-left: var(--spacing-08);
      }

      &:hover {
        background-color: var(--sparky-grey-100);
      }
    }
  }
}
