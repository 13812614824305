.panel {
  padding: 16px;
  width: 100%;

  .table {
    overflow-y: scroll;

    thead {
      position: relative;
      z-index: unset;
      background-color: var(--sparky-brand-white);

      .headerRow {
        background-color: var(--sparky-brand-white);

        .tableHeader {
          border: 1px solid var(--sparky-grey-300);
          padding: var(--spacing-08) var(--spacing-16);
        }
      }
    }

    .tableBody {
      overflow-y: scroll;

      .bodyRow {
        background-color: var(--sparky-brand-white);

        .tableCell {
          border: 1px solid var(--sparky-grey-300);
          padding: var(--spacing-08) var(--spacing-16);
        }
      }
    }

    .tableFooter {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
