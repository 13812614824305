.feedbackBar {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 4px;
}

.icon {
  padding: 8px;
}

.popoverContent {
  z-index: 200;
  border: 1px solid var(--sparky-grey-200);
}

.feedback {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 400px;
  margin-top: 6px;
  gap: 4px;

  .feedbackTitle {
    width: 90%;
  }

  .responseText {
    margin-top: 4px;
  }

  .feedbackButton {
    position: relative;
    width: fit-content;
    margin-left: auto;
  }

  textarea {
    height: 75px;
    padding: 12px 8px;
    border-color: var(--sparky-grey-200);
    resize: none;

    &::placeholder {
      color: var(--sparky-grey-400);
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
    }
  }
}
