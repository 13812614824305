.marketSection {
  padding: 0 12px;

  &.openState {
    background-color: var(--sparky-background);
  }

  .panel {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    padding: 16px;

    .topSection {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      gap: 8px 16px;
      align-items: center;

      .trendIcon {
        display: flex;
        align-items: center;
      }
    }
  }
}
