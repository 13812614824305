.spinner {
  display: inline-block;
  border-top: 2px solid currentcolor;
  border-right: 2px solid currentcolor;
  border-bottom-style: solid;
  border-left-style: solid;
  border-radius: 99999px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-bottom-color: transparent;
  border-left-color: transparent;
  -webkit-animation: 0.45s linear 0s infinite normal none running icon-spin;
  animation: 0.45s linear 0s infinite normal none running icon-spin;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transform-origin: center;
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    transform-origin: center;
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

//--font-size-10
.spinner__sm {
  width: var(--spinner-size);
  height: var(--spinner-size);
  --spinner-size: 0.625rem;
}

//--font-size-14
.spinner__md {
  width: var(--spinner-size);
  height: var(--spinner-size);
  --spinner-size: 0.875rem;
}

//--font-size-18
.spinner__lg {
  --spinner-size: 1.125rem;
  width: var(--spinner-size);
  height: var(--spinner-size);
}
