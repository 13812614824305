.baseInput {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  color: var(--text-primary);
  padding: 0 8px;
  transition: all ease-in-out 0.2s;
  width: 361px;

  &.sm {
    height: 32px;
  }

  &.md {
    height: 44px;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
  }

  .inputWrapper {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 8px;

    input {
      width: 100%;
      border: none;
      display: flex;
      background: none;
      color: var(--text-primary);

      &::placeholder {
        color: var(--text-placeholder);
      }

      &:focus-visible {
        outline: none;
      }
    }

    .buttonGroup {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .increaseButton {
        display: flex;
        padding-top: 8px;
        transform: translateY(2px);

        &:not([disabled]) {
          cursor: pointer;

          &:active {
            transform: scale(0.9);
          }
        }
      }

      .decreaseButton {
        display: flex;
        padding-bottom: 8px;
        transform: translateY(-2px);

        &:not([disabled]) {
          cursor: pointer;

          &:active {
            transform: scale(0.9);
          }
        }
      }
    }
  }

  &.default {
    border-color: var(--border-subtle-01);
    background-color: var(--bg);

    &:focus-within {
      box-shadow: 0 0 0 1px var(--bg), 0 0 0 3px var(--border-focused);
    }

    .inputWrapper {
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px var(--bg) inset !important;
      }
    }
  }

  &.success {
    border-color: var(--status-success);
    background-color: var(--bg);
    box-shadow: 0 2px 4px 0 rgba(24, 159, 81, 0.15);

    &:focus-within {
      box-shadow: 0 0 0 1px var(--bg), 0 0 0 3px var(--border-focused), 0 2px 4px 0 #189f5126;
    }

    .inputWrapper {
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px var(--bg) inset !important;
      }
    }
  }

  &.warning {
    border-color: var(--sparky-orange-400);
    background-color: var(--sparky-orange-100);
    box-shadow: 0 2px 4px 0 #ff000026;

    &:focus-within {
      box-shadow: 0 0 0 1px var(--bg), 0 0 0 3px var(--sparky-blue-300), 0 2px 4px 0 #ff000026;
    }

    .inputWrapper {
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px var(--sparky-orange-100) inset !important;
      }
    }
  }

  &.error {
    border-color: var(--status-error);
    background-color: var(--status-error-bg);
    box-shadow: 0 2px 4px 0 rgba(255, 0, 0, 0.15);

    &:focus-within {
      box-shadow: 0 0 0 1px var(--sparky-brand-white), 0 0 0 3px var(--border-focused),
        0 2px 4px 3px #ff000026;
    }

    .inputWrapper {
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px var(--status-error-background) inset !important;
      }
    }
  }

  &.disabled {
    background-color: var(--bg-disabled);
    color: var(--text-disabled);
    border-color: var(--border-subtle-01);

    input {
      color: var(--sparky-grey-300);
    }
  }
}
