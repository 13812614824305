.favouritesSelect {
  display: flex;
  gap: var(--spacing-04);

  .moreFavouriteInventoryConfigs {
    display: flex;
    align-items: center;
    border-radius: var(--spacing-02);
    padding: 3px;
    background-color: var(--sparky-grey-100);
    gap: var(--spacing-04);
    cursor: pointer;

    &[data-state='open'] {
      .icon {
        transform: rotate(180deg);
      }
    }

    .moreFavourites {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.moreFavouriteInventoryConfigsPopover {
  display: flex;
  flex-direction: column;
  background-color: var(--sparky-brand-white);
  padding: var(--spacing-16);
  box-shadow: var(--elevation-2);
  border-radius: var(--border-radius-04);
  min-width: 300px;
  max-height: 480px;
  overflow-y: auto;

  .option {
    padding: var(--spacing-08);
    display: flex;
    gap: var(--spacing-08);
    align-items: center;
    min-height: var(--spacing-40);
    cursor: default;
    justify-content: space-between;

    &.selectedOption {
      background-color: var(--sparky-blue-100);
    }

    &:hover {
      background-color: var(--sparky-grey-100);
    }
  }
}
