.mapPopup {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: var(--spacing-08);

  .tooltipRow {
    display: flex;
    justify-content: space-between;
  }

  .dealerList {
    display: flex;
    flex-direction: column;

    .dealerRow {
      display: flex;
      column-gap: var(--spacing-04);
      align-items: center;
    }
  }
}
