.resetPwdFormWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--spacing-08);
  padding: 60px 80px;
  color: var(--sparky-text-primary-white);

  .backwardButton {
    background-color: var(--sparky-navy-400) !important;
    margin-bottom: var(--spacing-48);
  }

  .resetPwdForm {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: var(--spacing-32);

    .formHeader {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: var(--spacing-08);

      .message {
        padding: 0 6px;
      }
    }

    .textInput,
    .summitButton {
      width: 100%;
    }

    .errorMessage {
      color: var(--sparky-red-300);
    }

    .summitButton {
      background-color: var(--sparky-brand-white);
      color: var(--sparky-navy-400);
      width: 100%;

      &:hover {
        background-color: var(--sparky-navy-200) !important;
      }
    }
  }
}
