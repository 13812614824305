.chatInput {
  width: 1000px;
  max-width: 80vw;
  margin-top: 24px;
}

.popoverContent {
  z-index: 200;
  width: 1000px;
  max-width: 80vw;
  padding: 16px;
  max-height: 350px;
  margin-top: 4px;
  overflow: auto;

  &.chatOpen {
    max-height: 135px;
  }

  &.showScroll {
    overflow-y: scroll;
  }
}

.promptSuggestionRow {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: var(--sparky-background);
  }
}
