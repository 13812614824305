.SoldInventoryWidget {
  display: flex;
  flex-direction: row;
  flex: auto;
  padding: var(--spacing-16);
  border: 1px solid var(--sparky-grey-200);
  box-shadow: none !important;
}

.left {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: var(--spacing-16);
}

.inline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.right {
  display: grid;
  align-items: center;
  justify-items: left;
  flex: 2;
  grid-template-columns: repeat(3, auto);
  margin-left: var(--spacing-12);

  &:last-child {
    border-bottom: 1px solid var(--sparky-grey-200);
  }
}

.row {
  display: contents;
  font-size: 14px;

  & > * {
    padding-left: var(--spacing-16);
    align-content: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    margin-top: -1px;
    border-top: 1px solid var(--sparky-grey-200);
  }
}

.dash {
  color: var(--sparky-grey-300);
}

.brandRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.brand {
  color: var(--sparky-brand-red-default);
}

.img {
  padding-right: var(--spacing-04);
}

.rowTitles {
  color: var(--sparky-text-secondary);
}

.dropdown {
  text-align: left;
}

@media screen and (max-width: 1300px) {
  .left {
    gap: 12px;
  }
}
