.addBudgetSidebar {
  display: none;
  background-color: var(--sparky-brand-white);

  &.showAddBudgetSidebar {
    z-index: 1;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--sparky-grey-100);
    width: 30rem;
    height: calc(100% - var(--spacing-48));
    position: absolute;
    top: var(--spacing-24);
    right: var(--spacing-24);
    bottom: var(--spacing-24);
    box-shadow: -6px 6px 12px 0 rgba(0, 0, 0, 0.25);
  }

  .sidebarHeader {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-12) var(--spacing-12) var(--spacing-08);
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: hidden;

    .messageBox,
    .quickSelect {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-08);
      padding: 0 var(--spacing-12);
    }

    .addBudgetList {
      overflow: auto;
      padding: 0 var(--spacing-12);
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: auto;
    gap: var(--spacing-08);
    padding: var(--spacing-12);
    border-top: 1px solid var(--sparky-grey-100);
  }
}

.addBudgetModal {
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  max-width: min(90vw, 75rem);
  padding: var(--spacing-32);
  background-color: var(--sparky-brand-white);

  .closeButton {
    position: absolute;
    top: var(--spacing-08);
    right: var(--spacing-08);
  }

  .messageBox {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-08);
    padding: var(--spacing-08) 0;
  }

  .addBudgetTable {
    position: relative;
    overflow: auto;
  }

  .summaryBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-08);
    background-color: var(--sparky-background);
    border-top: 1px solid var(--sparky-grey-300);
    border-bottom: 1px solid var(--sparky-grey-300);
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    gap: var(--spacing-08);
    padding-top: var(--spacing-12);

    .message {
      margin-right: auto;
    }
  }
}
