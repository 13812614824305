.acquisitionTable {
  position: relative;
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--sparky-grey-100);
  width: 100%;
  font-size: 14px;

  &.withSidebar {
    width: calc(100% - 18rem);
  }

  .tableContent {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: auto;

    .mainTable {
      width: fit-content;
      height: fit-content;
    }

    .acquisitionActionTable {
      margin-left: -1px;
      width: fit-content;
      height: fit-content;
    }

    .acquisitionTableHeader {
      position: sticky;
      top: 0;
      background: var(--sparky-brand-white);
      z-index: 1;

      .selectAll {
        padding: var(--spacing-08) var(--spacing-16);
        height: 3.75rem;
        background-color: #fff;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: var(--sparky-grey-100);
          cursor: pointer;
        }
      }

      .acquisitionHeader {
        white-space: nowrap;
        background-color: #fff;
        height: 62px;
        color: var(--sparky-grey-400);
        z-index: 1;
        text-align: left;
        cursor: default;

        &.acquisitionHeaderHover:hover {
          background-color: var(--sparky-grey-100);
          cursor: pointer;
        }

        &.compactHeader {
          font-size: 14px;
        }
      }
    }

    .cell {
      height: var(--table-row-height);
      border-top: 1px solid var(--sparky-grey-100);
      border-bottom: 1px solid var(--sparky-grey-100);
      padding: var(--spacing-04);

      &.checkbox {
        margin: auto;
        border-left: none;
        background-color: var(--sparky-brand-white);
        cursor: default;
        text-align: center;
      }

      &.selectCell {
        background-color: var(--sparky-grey-200);
      }

      &.actionCell {
        margin-left: -1px;
      }
    }
  }
}

.noPadding {
  height: var(--table-row-height);
  padding: 0 !important;
}
