.tooltip {
  display: grid;
  grid-gap: var(--spacing-04) var(--spacing-08);
  align-items: center;
  grid:
    'img title' auto
    'img vin' auto
    'img details' auto
    'click click' auto
    / auto auto;
  box-shadow: var(--elevation-3);
  width: fit-content;

  img {
    grid-area: img;
    height: 96px;
    width: 96px;
    vertical-align: middle;
    object-fit: cover;
  }

  .details {
    background-color: var(--sparky-grey-100);
  }
}
