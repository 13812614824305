.dealerSelectButton {
  display: inline-flex;
  align-items: center;
  color: #000;
  cursor: pointer;

  &.disabled {
    color: var(--sparky-grey-300);
  }

  &.marketMap {
    background-color: var(--sparky-brand-white);
    border-radius: 12px;
    border: 1px solid var(--sparky-grey-200);
    padding: 10px 16px;
  }
}

.marketMapContainer {
  display: flex;
  justify-content: flex-end;
}

.dealerSelectModal {
  padding: 16px;
  width: 32rem;
  background-color: var(--sparky-brand-white);
}

.dealerSelectPopover {
  width: 32rem;
}
