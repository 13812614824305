.inventoryNavDropdownWrapper {
  display: flex;
}

.active {
  color: var(--sparky-red-400);
}

.inventoryNavDropdown {
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 100%;
  max-height: 850px;
  overflow: hidden;
}

.inventoryLink {
  color: black;
  text-decoration: none;
  padding-block: 8px;
  padding-left: 8px;

  &:hover {
    background-color: var(--sparky-grey-100);

    .inventoryLinkText {
      font-weight: 700 !important;
    }
  }
}

.inventoryDropdownSection {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &Title {
    padding-block: 16px;
  }
}

.inventoryWrapper {
  width: 100%;
  overflow: auto;
  padding-left: 8px;
}

.inventorySection {
  display: flex;
  flex: 1;
  overflow: hidden;
}
