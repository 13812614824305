.selectedVin {
  color: var(--sparky-grey-400) !important;
  background-color: var(--sparky-grey-100) !important;
}

.addBudgetPillNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: var(--border-radius-10);
  background-color: var(--sparky-blue-100);
  color: var(--sparky-blue-400);
}

.inventoryHeader {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  padding: 24px;

  &.compactView {
    padding: var(--spacing-12) var(--spacing-24) 0;
  }

  .mainHeader {
    display: flex;
    position: relative;
    align-items: center;
    gap: var(--spacing-08);

    .closeButton {
      display: inline-flex;
      align-items: center;
      width: fit-content;
      transition: all ease-in-out 0.2s;

      &:hover:not([disabled]) {
        color: var(--sparky-red-400);
        background-color: var(--sparky-navy-100);
        border-radius: var(--border-radius-04);
        cursor: pointer;
      }
    }

    .pillNumber {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: var(--border-radius-10);
      background-color: var(--sparky-navy-100);
      color: var(--sparky-blue-500);
    }

    .exclamationMark {
      stroke: var(--sparky-brand-white);
      background-color: var(--sparky-orange-400);
      border-radius: 50%;
    }

    .searchBar {
      border-top: none;
      padding: 0;
    }
  }

  .subHeader {
    display: flex;
    align-items: center;

    .sortState {
      display: flex;
      align-items: center;
      border: 2px solid var(--sparky-grey-300);
      height: var(--spacing-24);
      width: fit-content;
      border-radius: var(--spacing-12);
      padding: 0 var(--spacing-08) 0 var(--spacing-02);
      gap: var(--spacing-04);
      color: var(--sparky-grey-400);

      &:hover {
        color: var(--sparky-navy-400);
        border-color: var(--sparky-navy-400);
      }
    }

    .filters {
      padding: 0 var(--spacing-08);
      margin-left: var(--spacing-08);
      border-left: 2px solid var(--sparky-grey-300);
    }
  }

  .inventoryConfigDetails {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .inventoryConfigDetailsInput {
      display: flex;
      width: fit-content;
      align-items: center;
      gap: 4px;
      cursor: default;
    }
  }
}

.newFeatureMessage {
  width: 673px;
  position: absolute;
  top: 150px;
  left: 24px;
  box-shadow: var(--elevation-2);
  transition: all ease-in-out 0.2s;
  z-index: 10;

  &.compactInventoryView {
    top: 100px;
  }

  &.highlightsOrSummary {
    top: 350px;
  }

  &.compactHighlightsOrSummary {
    top: 300px;
  }
}

.viewChangedMessage {
  width: 405px;
  position: absolute;
  top: 150px;
  right: 24px;
  box-shadow: var(--elevation-2);
  transition: all ease-in-out 0.2s;
  z-index: 10;

  &.compactInventoryView {
    top: 100px;
  }

  &.highlightsOrSummary {
    top: 350px;
  }

  &.compactHighlightsOrSummary {
    top: 300px;
  }

  .messageContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .buttonContainer {
      display: flex;
      justify-content: flex-end;
      gap: 12px;

      .customButton {
        background-color: transparent;
      }

      .createNewButton {
        margin-right: auto;
      }
    }
  }
}

.densityPopoverContainer {
  display: flex;
  padding: var(--spacing-08);
  flex-direction: column;
  width: 240px;

  .popoverRow {
    padding: var(--spacing-08);
  }

  .divider {
    margin: 6px 0;
  }

  .popoverOptionRow {
    display: flex;
    gap: var(--spacing-08);
    padding: var(--spacing-08);
    align-items: center;

    &:hover {
      background-color: var(--sparky-grey-100);
      cursor: pointer;
    }
  }
}

.densityButton {
  height: 2rem !important;
  width: 2rem !important;
}

.deleteTableViewModal,
.creatingTableViewModal {
  position: relative;
  display: flex;
  width: 750px;
  flex-direction: column;
  gap: var(--spacing-12);
  padding: var(--spacing-32);
  align-items: center;
  border-radius: var(--border-radius-04);
  background-color: var(--sparky-brand-white);

  .optionContainer {
    width: 100%;
    padding: var(--spacing-16);
    background-color: var(--sparky-grey-100);
  }

  .optionContainer,
  .buttonContainer {
    display: flex;
    justify-content: center;
    gap: var(--spacing-16);
  }

  .closeModalButton {
    position: absolute;
    top: var(--spacing-08);
    right: var(--spacing-08);
  }
}

[data-reach-dialog-content] {
  background-color: transparent;
}
