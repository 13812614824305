.leadsSection {
  padding: 0 12px;

  &.openState {
    background-color: var(--sparky-background);
  }

  .panel {
    width: 100%;
    padding: 16px;
  }
}
