.chartContainer {
  height: 80%;
  width: 100%;

  .relativeContainer {
    width: 100%;
    height: 100%;
    position: relative;

    &.hasLegend {
      height: calc(100% - 50px);
    }

    .absoluteContainer {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
    }
  }
}

.tooltipContainer {
  padding: var(--spacing-08);
  background-color: var(--sparky-brand-white);
  box-shadow: var(--sparky-elevation-3);
  display: flex;
  flex-direction: column;

  .flexRow {
    display: flex;
    align-items: center;
  }

  .tooltipRow {
    display: flex;
    justify-content: space-between;
    column-gap: var(--spacing-12);
    min-width: 240px;

    .colorCircle {
      height: var(--spacing-08);
      width: var(--spacing-08);
      border-radius: 100%;
      margin-right: var(--spacing-04);
    }

    .metricLabel {
      line-height: 150%;
    }
  }
}
