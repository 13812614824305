.changePwdWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 60px 80px;
  color: var(--sparky-text-primary-white);

  .header {
    margin-bottom: var(--spacing-48);
  }

  .changePwdForm {
    display: flex;
    align-items: center;
    flex-direction: column;

    .message {
      padding: 0 6px;

      .emphasize {
        color: var(--sparky-red-300);
      }
    }

    .textInput,
    .changePwdButton {
      width: 100%;
    }

    .errorMessage {
      color: var(--sparky-red-300);
    }

    .changePwdButton {
      margin-top: var(--spacing-32);
      background-color: var(--sparky-brand-white);
      color: var(--sparky-navy-400);

      &:hover {
        background-color: var(--sparky-navy-200) !important;
      }
    }
  }
}
