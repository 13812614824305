.content {
  display: flex;
  z-index: 999 !important;
  align-items: center;
  justify-content: center;
  min-height: 36px;
  font-size: 12px;
  color: var(--text-inverse);
  background-color: var(--bg-inverse);
  padding: 8px;
  border-radius: var(--border-radius-08);
  box-shadow: var(--sparky-elevation-3);
}
