.tooltipContainer {
  padding: var(--spacing-08);
  background-color: var(--sparky-brand-white);
  box-shadow: var(--sparky-elevation-3);
  display: flex;
  flex-direction: column;

  .flexRow {
    display: flex;
    align-items: center;
  }

  .tooltipRow {
    display: flex;
    justify-content: space-between;
    column-gap: var(--spacing-12);
    min-width: 240px;

    .metricLabel {
      line-height: 150%;
    }
  }
}

.colorCircled {
  height: var(--spacing-08);
  width: var(--spacing-08);
  border-radius: 100%;
  margin-right: var(--spacing-08);
  margin-left: var(--spacing-02);
}

.colorDash {
  height: var(--spacing-04);
  width: var(--spacing-12);
  border-radius: 2px;
  margin-right: var(--spacing-08);

  &.dashedLine {
    width: var(--spacing-04);
    height: 3px;

    &:first-child {
      margin-right: var(--spacing-04);
    }
  }
}
