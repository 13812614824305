.examples {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-08);
  padding-top: var(--spacing-24);

  width: 100%;
  max-width: 24rem;

  h3,
  h4 {
    margin-bottom: 0.5em;
  }
}

.warning {
  display: flex;
  gap: var(--spacing-08);
  padding: var(--spacing-08);

  border-radius: var(--border-radius);
  border: 1px solid #0003;
  background-color: #ffd4ae;

  .warningIcon {
    color: #fd7800;
    transform: translateY(-0.25em);
  }
}
