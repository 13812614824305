.closeButton {
  display: flex;
}

.titleContainer {
  display: flex;
  column-gap: var(--spacing-08);
  align-items: center;

  .lexIcon {
    svg {
      height: 32px;
      width: 32px;
      margin-right: 6px;
      cursor: pointer;
    }
  }

  .beta {
    padding: var(--spacing-04) var(--spacing-08);
    border: 1px solid var(--sparky-red-400);
    border-radius: 99px;
    color: var(--sparky-red-400);
    font-size: var(--font-12);
    font-weight: bold;
    display: inline-block;
  }
}

.dealerSelectContainer {
  display: flex;
  position: relative;
}
