.marketmap {
  display: flex;
  flex: 1;
  height: 100%;
  background-color: #eee;

  .mapContainer {
    height: 100%;
    width: 100%;
    background-color: var(--sparky-brand-white);
    position: relative;
  }

  .mapOverlay {
    width: 100%;
    overflow: visible;
    height: 0;
    pointer-events: none;
  }
}

.sliderToggleButton {
  position: absolute;
  top: 130px;
  right: var(--spacing-12);
  pointer-events: all;
  z-index: 51;

  &.open {
    button {
      background-color: var(--sparky-navy-100);
      border: 1px solid var(--sparky-navy-400);
    }
  }

  button {
    background-color: var(--sparky-brand-white);
    width: 28px !important;
    height: 28px !important;
  }
}

.sliderPopover {
  position: absolute;
  z-index: 50;
  top: 120px;
  right: var(--spacing-24);
  background-color: var(--sparky-brand-white);
  border-radius: 4px;
  pointer-events: all;
  border: 1px solid var(--sparky-grey-200);
  padding: var(--spacing-12);
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-08);

  .sliderRow {
    display: flex;
    margin-top: var(--spacing-08);
    column-gap: var(--spacing-08);

    .textInput {
      width: 52px;
    }
  }

  .sliderContainer {
    display: flex;
    height: 44px;
    align-items: center;
    column-gap: var(--spacing-08);
  }
}

.popupContainer {
  min-width: 250px;
  max-width: 500px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  pointer-events: none;
  border-radius: 4px;
  background-color: var(--sparky-brand-white);
  border: 1px solid var(--sparky-grey-200);
  box-shadow: var(--sparky-elevation-2);
}

.infoMessageContainer {
  position: absolute;
  bottom: var(--spacing-32);
  right: var(--spacing-24);
  z-index: 50;
  max-width: 500px;
  pointer-events: all;

  .pointList {
    display: flex;
    flex-direction: column;

    .point {
      display: flex;
      column-gap: var(--spacing-08);
    }

    .bullet {
      display: block;
      margin-top: 5px;
      height: 6px;
      width: 6px;
      background-color: var(--sparky-blue-400);
      border-radius: 100%;
    }
  }
}
