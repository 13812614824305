.CTAFilter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--border-radius);
  height: 8rem;
  gap: var(--spacing-08);
  padding: var(--spacing-16);

  .filterLabel {
    text-align: center;
  }

  .button {
    min-width: 6rem;
    margin-top: var(--spacing-12);
  }
}
