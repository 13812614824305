.dropdownOptions {
  display: flex;
}

.dropdownInnerSelectInput {
  min-height: 40px;
  height: auto;
  word-break: break-word;
}

.dropdownSelectInput {
  min-height: 40px;
  height: auto;
  word-break: break-word;
  text-align: start !important;
}
