.blankDropdown {
  display: flex;
}

.selectInput {
  display: inline-flex;
  align-items: center;
  gap: var(--spacing-08);
  border-width: 1px;
  border-style: solid;
  border-radius: var(--border-radius-04);
  padding: 0 var(--spacing-08);
  color: var(--sparky-text-primary);
  transition: all ease-in-out 0.2s;
  width: 361px;
  height: 44px;

  &[data-placeholder='true'] {
    color: grey;
  }

  &.sm {
    height: 32px;
  }

  &.placeholder {
    color: var(--sparky-grey-400);
  }

  &:focus-visible {
    outline: none;
  }

  &.default {
    border-color: var(--sparky-grey-200);
    background-color: var(--sparky-brand-white);

    &:focus-within {
      box-shadow: 0 0 0 1px var(--sparky-brand-white), 0 0 0 3px var(--sparky-blue-300);
    }
  }

  &.success {
    border-color: var(--sparky-green-400);
    background-color: var(--sparky-brand-white);
    box-shadow: 0 2px 4px 0 #189f5126;

    &:focus-within {
      box-shadow: 0 0 0 1px var(--sparky-brand-white), 0 0 0 3px var(--sparky-blue-300),
        0 2px 4px 3px #189f5126;
    }
  }

  &.warning {
    border-color: var(--sparky-orange-400);
    background-color: var(--sparky-orange-100);
    box-shadow: 0 2px 4px 0 #ff000026;

    &:focus-within {
      box-shadow: 0 0 0 1px var(--sparky-brand-white), 0 0 0 3px var(--sparky-blue-300),
        0 2px 4px 0 #ff000026;
    }
  }

  &.noBorder {
    border: none;
    width: fit-content;
    color: var(--sparky-text-primary);
  }

  &.error {
    border-color: var(--sparky-red-400);
    background-color: var(--sparky-red-100);
    box-shadow: 0 2px 4px 0 #ff000026;

    &:focus-within {
      box-shadow: 0 0 0 1px var(--sparky-brand-white), 0 0 0 3px var(--sparky-blue-300),
        0 2px 4px 3px #ff000026;
    }
  }

  &.disabled {
    background-color: var(--sparky-background);
    color: var(--sparky-grey-300);
  }

  &[data-state='open'] {
    .selectInputIcon {
      transform: rotate(180deg);
    }
  }

  .variantIcon {
    display: flex;
    align-items: center;
    gap: var(--spacing-08);
    margin-left: auto;
  }

  .selectInputDivider,
  .selectInputIcon {
    display: flex;
    align-items: center;
    color: var(--sparky-grey-400);

    &::selection {
      background: transparent;
    }
  }
}

.selectInputContent {
  width: var(--radix-popper-anchor-width);
  background-color: var(--sparky-brand-white);
  padding: var(--spacing-12);
  border: 1px solid #d0d0d0;
  border-radius: var(--border-radius-04);
  box-shadow: var(--sparky-elevation-2);
  overflow-y: auto;
  z-index: 200;
}

.selectItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: var(--spacing-08) var(--spacing-12);

  &[data-state='checked'] {
    color: var(--sparky-navy-400);
    font-weight: 700;
  }

  &:focus-visible {
    outline: none;
  }

  &:hover {
    cursor: pointer;
    background-color: var(--sparky-grey-100);
  }
}
