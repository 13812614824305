.noDataAvailable {
  background: url('../../NoVinAvaliableBackgroundImg.jpg'), no-repeat;
  background-size: cover;
  background-position: center 30%;
  padding: 60px;
  border-radius: 4px;

  .noDataAvailableText {
    max-width: 500px;
  }
}
