@import '../../Sparky/styles/layouts';
@import '../../Sparky/styles/mixins';

.signInWrapper {
  position: relative;
  display: grid;
  grid-template-columns: 1.4fr 1fr;
  justify-content: center;
  width: 1200px;
  height: 660px;
  background-color: var(--sparky-navy-400);
  border-radius: var(--spacing-12);
  overflow: hidden;
  box-shadow: 24px 24px 48px 0 rgba(0, 0, 0, 0.25);

  .signInImg {
    background-color: lightgray;
    background-image: url('SignInImg.jpg');
    background-size: cover;
    mix-blend-mode: luminosity;
  }

  .alertMessage {
    position: absolute;
    top: 32px;
    left: 32px;
    width: 523px;
  }
}
