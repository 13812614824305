.highlightsToggle {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 0;
  transition: all ease-in-out 0.2s;
  overflow: hidden;
  margin: 0 var(--spacing-24);
  border-color: var(--sparky-grey-100);

  &.isShow {
    height: 11rem;
    border-width: 1px;
    border-style: solid;
    border-radius: var(--border-radius-08);
    margin: var(--spacing-24) var(--spacing-24) 0;
    flex-shrink: 0;
  }
}
