.riskScoreIconRed {
  border-radius: 50%;
  background-color: var(--sparky-red-400);
}

.riskScoreIconOrange {
  border-radius: 50%;
  background-color: var(--sparky-orange-400);
}

.riskScoreIconYellow {
  border-radius: 50%;
  background-color: var(--sparky-yellow-400);
}

.riskScoreIconLime {
  border-radius: 50%;
  background-color: var(--sparky-lime-400);
}

.riskScoreIconGreen {
  border-radius: 50%;
  background-color: var(--sparky-green-400);
}
