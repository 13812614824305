.toggleOptions {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04);

  .option {
    display: flex;
    justify-content: space-between;
  }
}

.tooltip {
  z-index: 2;
  display: flex;
  align-items: center;
}

.toggleOption {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .muted {
    opacity: 0.5;
  }

  .itemLabel {
    word-break: break-word;
  }
}

.itemCount {
  color: var(--color-neutral-5);
  white-space: nowrap;
  margin-left: var(--spacing-08);
}
