@import './Sparky/styles/vars';
@import './Sparky/styles/layouts';
@import './overrides';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://api.mapbox.com/mapbox-gl-js/v3.0.0/mapbox-gl.css');

html {
  min-width: 62em;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  color: var(--color-text-primary);
}

#root {
  height: 100vh;
}

:root {
  --spacing-10: 10px;
  --gap-md: var(--spacing-08);
  --gap-lg: var(--spacing-16);
  --modal-width: min(90vw, 32rem);
  --floating-panel-width: min(100%, 32rem);
  --nav-bar-height: 60px;
  --nav-height: var(--nav-bar-height);
  --content-height: calc(100vh - var(--nav-height));
  --content-side-padding: var(--spacing-08);

  // Sparky Colour Palette
  --sparky-brand-red-default: #f41424;
  --sparky-brand-red-500: #ab0e19;
  --sparky-brand-red-300: #f98991;
  --sparky-brand-white: #fff;
  --sparky-brand-black: #000;
  --sparky-text-primary: #000;
  --sparky-text-secondary: var(--sparky-grey-600);
  --sparky-text-primary-white: #fff;
  --sparky-text-white: var(--sparky-text-primary-white);
  --sparky-text-navy: var(--sparky-navy-400);
  --sparky-text-blue: var(--sparky-blue-500);
  --sparky-text-green: var(--sparky-green-500);
  --sparky-text-grey: var(--sparky-grey-500);
  --sparky-text-red: var(--sparky-red-500);
  --sparky-text-purple: var(--sparky-purple-500);
  --sparky-text-orange: var(--sparky-orange-500);
  --sparky-text-yellow: var(--sparky-yellow-600);
  --sparky-text-lime: var(--sparky-lime-600);
  --sparky-text-warning: var(--sparky-orange-400);
  --sparky-text-error: var(--sparky-red-400);
  --sparky-text-disabled: var(--sparky-grey-300);

  --sparky-text-tertiary: #5f5f5f;
  --sparky-background: #fbfbfb;

  --sparky-elevation-1: 0 2px 4px 0 #00000026;
  --sparky-elevation-2: 0 4px 8px 0 #0003;
  --sparky-elevation-3: 0 6px 12px 0 #00000040;

  --sparky-boxshadow-left: -5px 0 5px -3px rgba(0, 0, 0, 0.2);
  --sparky-boxshadow-right: 5px 0 5px -3px rgba(0, 0, 0, 0.2);

  @media ($layout-sm) {
    --gap-md: var(--spacing-16);
    --gap-lg: var(--spacing-32);
    --content-side-padding: var(--spacing-16);
  }

  @media ($layout-lg) {
    --gap-md: var(--spacing-24);
    --gap-lg: var(--spacing-48);
    --content-side-padding: var(--spacing-24);
  }

  @media ($layout-xxl) {
    --content-side-padding: var(--spacing-64);
  }

  /* Sparky 2.0 pallette */

  // Text
  --text-primary: black;
  --text-secondary: var(--sparky-grey-600);
  --text-placeholder: var(--sparky-grey-400);
  --text-disabled: var(--sparky-grey-300);
  --text-helper: var(--sparky-grey-500);
  --text-inverse: var(--sparky-text-white);
  --text-error: var(--sparky-red-500);
  --link-primary: var(--sparky-blue-500);
  --link-inverse: var(--sparky-blue-300);
  --link-visited: var(--sparky-purple-500);
  --link-inverse-visited: var(--sparky-purple-300);

  // Background and borders
  --bg: white;
  --bg-inverse: black;
  --bg-hover: rgba(13, 43, 65, 0.1);
  --bg-selected: var(--sparky-navy-500);
  --bg-disabled: var(--sparky-navy-50);
  --bg-selected-disabled: var(--sparky-navy-300);
  --bg-attention: var(--sparky-brand-500);
  --layer-01: var(--sparky-navy-50);
  --layer-02: var(--sparky-navy-100);
  --layer-hover-01: var(--sparky-navy-500);
  --border-subtle-01: var(--sparky-grey-200);
  --border-subtle-02: var(--sparky-grey-300);
  --border-strong-01: var(--sparky-grey-500);
  --border-strong-02: var(--sparky-grey-600);
  --border-active-01: var(--sparky-navy-500);
  --border-active-02: var(--sparky-brand-500);
  --border-focused: var(--sparky-blue-300);
  --border-active-disabled-01: var(--sparky-navy-200);
  --border-active-disabled-02: var(--sparky-brand-200);

  // button
  --button-primary: var(--sparky-navy-500);
  --button-secondary: white;
  --button-hover-01: var(--sparky-navy-350);
  --button-hover-02: #0d2b411a;
  --button-secondry-border: var(--sparky-navy-200);
  --button-secondary-border-hover: var(--sparky-navy-500);
  --button-text-01: white;
  --button-text-02: var(--sparky-navy-500);
  --button-danger-primary: var(--sparky-red-500);
  --button-danger-hover-01: var(--sparky-red-600);
  --button-danger-hover-02: #b200001a;
  --button-danger-secondary-outline: var(--sparky-red-400);
  --button-danger-text-01: white;
  --button-danger-text-02: #b200001a; // red-500 with 10% opacity
  --button-danger-secondary-outline-hover: var(--sparky-red-600);
  --button-primary-disabled: var(--sparky-grey-300);
  --button-primary-disabled-border: var(--sparky-grey-300);
  --button-text-disabled-01: white;
  --button-text-disabled-02: var(--sparky-grey-300);

  // Switch
  --switch-on: var(--sparky-brand-400);
  --switch-off: var(--sparky-grey-300);
  --switch-on-hover: var(--sparky-brand-500);
  --switch-off-hover: var(--sparky-grey-400);
  --switch-off-border: var(--sparky-grey-400);
  --switch-on-disabled: var(--sparky-brand-300);
  --switch-off-disabled: var(--sparky-grey-200);
  --switch-off-disabled-border: var(--sparky-grey-300);

  // Status
  --status-default: var(--sparky-grey-400);
  --status-info: var(--sparky-blue-400);
  --status-warning: var(--sparky-orange-400);
  --status-success: var(--sparky-green-400);
  --status-error: var(--sparky-red-400);
  --status-default-bg: var(--sparky-grey-200);
  --status-info-bg: var(--sparky-blue-100);
  --status-warning-bg: var(--sparky-orange-100);
  --status-success-bg: var(--sparky-green-100);
  --status-error-bg: var(--sparky-red-100);
  --status-default-text: var(--sparky-grey-600);
  --status-info-text: var(--sparky-blue-500);
  --status-warning-text: var(--sparky-orange-500);
  --status-success-text: var(--sparky-green-500);
  --status-error-text: var(--sparky-red-500);

  // Chip
  --chip-grey: var(--sparky-grey-400);
  --chip-blue: var(--sparky-blue-400);
  --chip-orange: var(--sparky-orange-400);
  --chip-green: var(--sparky-green-400);
  --chip-red: var(--sparky-red-400);
  --chip-purple: var(--sparky-purple-400);
  --chip-pink: var(--sparky-pink-400);
  --chip-teal: var(--sparky-teal-400);
  --chip-yellow: var(--sparky-yellow-400);
  --chip-navy: var(--sparky-navy-400);
  --chip-lime: var(--sparky-lime-400);

  --chip-grey-bg: var(--sparky-grey-100);
  --chip-blue-bg: var(--sparky-blue-100);
  --chip-orange-bg: var(--sparky-orange-100);
  --chip-green-bg: var(--sparky-green-100);
  --chip-red-bg: var(--sparky-red-100);
  --chip-purple-bg: var(--sparky-purple-100);
  --chip-pink-bg: var(--sparky-pink-100);
  --chip-teal-bg: var(--sparky-teal-100);
  --chip-yellow-bg: var(--sparky-yellow-100);
  --chip-navy-bg: var(--sparky-navy-100);
  --chip-lime-bg: var(--sparky-lime-100);

  --chip-grey-text: var(--sparky-grey-500);
  --chip-blue-text-01: var(--sparky-blue-400);
  --chip-blue-text-02: var(--sparky-blue-500);
  --chip-orange-text-01: var(--sparky-orange-500);
  --chip-orange-text-02: var(--sparky-orange-600);
  --chip-green-text: var(--sparky-green-500);
  --chip-red-text: var(--sparky-red-500);
  --chip-purple-text-01: var(--sparky-purple-400);
  --chip-purple-text-02: var(--sparky-purple-500);
  --chip-pink-text: var(--sparky-pink-500);
  --chip-teal-text: var(--sparky-teal-400);
  --chip-yellow-text: var(--sparky-yellow-600);
  --chip-navy-text: var(--sparky-navy-400);
  --chip-lime-text: var(--sparky-lime-600);

  // Graphs, charts and visualizations
  --scale-good-01: var(--sparky-green-400);
  --scale-good-02: var(--sparky-lime-400);
  --scale-medium: var(--sparky-yellow-400);
  --scale-bad-01: var(--sparky-red-400);
  --scale-bad-02: var(--sparky-orange-400);
  --scale-good-muted-01: #189f5166; // green-400 40%
  --scale-good-muted-02: #90cc2866; // lime-400 40%
  --scale-medium-muted: #fcd34d66; // yellow-400 40%
  --scale-bad-01-muted: #f006; // red-400 40%
  --scale-bad-02-muted: #fd780066; // orange-400 40%
  --scale-good-text-01: var(--sparky-green-500);
  --scale-good-text-02: var(--sparky-lime-600);
  --scale-medium-text: var(--sparky-yellow-600);
  --scale-bad-text-01: var(--sparky-red-500);
  --scale-bad-text-02: var(--sparky-orange-500);
  --charts-01: var(--sparky-blue-400);
  --charts-02: var(--sparky-navy-400);
  --charts-03: var(--sparky-purple-400);
  --charts-04: var(--sparky-yellow-500);
  --charts-05: var(--sparky-pink-400);
  --charts-06: var(--sparky-turquoise-400);
  --charts-01-muted: #0d2b414d; // blue-400 opacity 30%
  --charts-02-muted: #0d2b4166; // navy-400 opacity 40%
  --charts-03-muted: #a90df866; // purple-400 opacity 40%
  --charts-04-muted: #7e6a2766; // yellow-400 opacity 40%
  --charts-05-muted: #b22d7d66; // pink-400 opacity 40%
  --charts-06-muted: #10a0a266; // turqoise-400 opacity 40%
  --charts-risk: var(--sparky-brand-400);
  --charts-risk-muted: #f414244d; // brand-400 opacity 30%
  --charts-subtle: var(--sparky-grey-300);
  --charts-subtle-mutled: #c3c3c34d; // grey-400 opacity 30%
  --charts-axis-lines-01: var(--sparky-grey-100);
  --charts-axis-lines-02: var(--sparky-navy-100);
  --charts-axis-lines-hover-01: var(--sparky-grey-300);
  --charts-axis-lines-hover-02: var(--sparky-navy-300);
  --charts-hover-line: var(--sparky-grey-400);

  // Icons
  --icon-action: var(--sparky-navy-500);
  --icon-subtle: var(--sparky-grey-400);
  --icon-disabled: var(--sparky-grey-300);
  --icon-inverse: white;
  --icon-strong: black;
  --icon-form: var(--sparky-grey-400);
  --icon-active-table: var(--sparky-blue-400);
  --icon-active-starred: var(--sparky-yellow-400);

  // Lex Chat Bot
  --lex-bg: var(--sparky-grey-50);
  --lex-text-primary: black;
  --lex-text-inverted: white;
  --lex-chat-01: var(--sparky-navy-400);
  --lex-chat-02: var(--sparky-grey-100);
  --lex-layer-01: white;
  --lex-user-avatar: var(--sparky-navy-400);
  --lex-logo: var(--sparky-brand-400);
  --lex-text-flare: linear-gradient(90deg, #f41424 0%, #860b14 100%);
}

[data-reach-dialog-overlay] {
  z-index: 200;
}

.toast-container {
  position: fixed;
  align-items: center;
  bottom: 0;
  left: 50%;
  translate: -50%;
  z-index: 300;
}

// mapbox overrides
.mapboxgl-popup-tip {
  border-right: 10px solid transparent !important;
  transform: translateX(-10px);
}

.mapboxgl-popup-content {
  box-shadow: var(--elevation-3);
}

.mapboxgl-popup {
  z-index: 10;
}

// removes watermarks -> not sure about legal
// .mapboxgl-ctrl-logo, .mapboxgl-ctrl-attrib-inner {
//   display: none !important;
// }
