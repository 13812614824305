.lexChatContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;

  overflow: hidden;
  border-radius: 0;
  width: 100vw;
  max-width: 100%;
  position: relative;
  background-color: var(--sparky-background);
  pointer-events: all;
  padding: 32px;

  .footer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.lexModal {
  display: flex;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;

  z-index: 1022;
}
