.popup {
  width: 225px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  background-color: var(--sparky-brand-white);
  pointer-events: none;
  border-radius: 4px;
  border: 1px solid var(--sparky-grey-200);
  box-shadow: var(--sparky-elevation-2);

  .popupContent {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: var(--spacing-08);

    .tooltipRow {
      display: flex;
      justify-content: space-between;
    }
  }
}

.mapContainer {
  height: 100%;
  width: 100%;
  background-color: var(--sparky-brand-white);
  position: relative;
}

.mapOverlay {
  width: 100%;
  overflow: visible;
  height: 0;
  pointer-events: none;

  .legendAbsoluteContainer {
    position: absolute;
    z-index: 5;
    bottom: var(--spacing-24);
    left: var(--spacing-16);
  }

  .legend {
    width: 250px;
    padding: var(--spacing-16);
    max-width: 100%;

    .legendItemRow {
      display: flex;
      column-gap: var(--spacing-08);
      align-items: center;
      padding: var(--spacing-08) 0;

      .legendIcon {
        display: flex;
        column-gap: var(--spacing-04);
      }
    }

    .gradiantBar {
      width: 100%;
      height: var(--spacing-12);
      margin-top: var(--spacing-08);
    }

    .minMaxRow {
      display: flex;
      flex-direction: 1;
      justify-content: space-between;
      margin-top: var(--spacing-08);
    }
  }

  .expandButton {
    bottom: var(--spacing-32);
    right: var(--spacing-24);
    position: absolute;
    z-index: 5;
    pointer-events: all;

    button {
      background-color: var(--sparky-brand-white);
    }
  }

  .fullScreenControls {
    position: absolute;
    top: var(--spacing-16);
    bottom: 6em;
    left: var(--spacing-24);
    z-index: 5;
    pointer-events: all;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    width: 286px;
    padding: var(--spacing-40) var(--spacing-32);

    .topControles {
      display: flex;
      flex-direction: column;
      position: relative;

      .togglePanelButton {
        position: absolute;
        right: -52px;

        button {
          background-color: var(--sparky-brand-white);
        }
      }
    }

    .metricRadioButtons {
      display: flex;
      flex-direction: column;
      row-gap: var(--spacing-16);
      margin-bottom: var(--spacing-48);
    }

    .backButton {
      svg {
        margin-right: var(--spacing-08);
      }
    }
  }

  .closedControlPanel {
    position: absolute;
    top: var(--spacing-16);
    left: var(--spacing-24);
    z-index: 5;
    pointer-events: all;
    display: flex;
    align-items: center;
    column-gap: var(--spacing-12);

    button {
      background-color: var(--sparky-brand-white);
    }
  }

  .closeIcon {
    position: absolute;
    top: var(--spacing-24);
    right: var(--spacing-48);
    z-index: 5;
    pointer-events: all;
    display: flex;
    align-items: center;

    button {
      border: none;
    }
  }
}
