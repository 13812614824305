.vehicleSummary {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  gap: var(--spacing-04);
  padding: var(--spacing-08);

  &.compact {
    padding: var(--spacing-04);
  }

  .vehicleSummaryText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .vehicleSummaryPopover {
    z-index: 2;
    cursor: auto;
    transition: all 0.5s ease-in 0.25s;
  }
}

.vehicleSummaryContainer {
  display: flex;
  height: 10rem;
  justify-content: center;
  gap: var(--spacing-12);
  padding: var(--spacing-12);

  .vehicleImage {
    position: relative;
    height: 100%;
    aspect-ratio: 1.33;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;

      &.blur {
        filter: blur(3px);
      }
    }

    .countContainer {
      // Overlay on the entire cell, used to calculate size for the imageCount container query
      container-type: size;
      position: absolute;
      bottom: 0;
      left: 0;
      top: 0;
      right: 0;

      .imageCount {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        background-color: rgba(255, 255, 255, 0.8);

        @container (height < 5rem) {
          visibility: hidden;
        }
      }
    }
  }

  .vehicleInfo {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;

    .condition {
      background-color: var(--color-neutral-3);
      border-radius: var(--spacing-12);
      width: fit-content;
      padding: var(--spacing-04) var(--spacing-08);
    }

    .vin,
    .stockNumber {
      display: flex;
      align-items: center;
      gap: var(--spacing-08);

      .link {
        color: var(--sparky-blue-500);
        text-decoration: underline;
        text-decoration-color: var(--sparky-blue-500) !important;
      }
    }
  }
}
