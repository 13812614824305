.inquirySuggestion {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: var(--spacing-16);

  background-color: #fff;
  border: 1px solid var(--color-neutral-4);
  border-radius: var(--border-radius);
  transition: box-shadow 0.25s;

  .arrow {
    flex-shrink: 0;
    margin-left: var(--spacing-16);
  }

  &:hover {
    box-shadow: var(--elevation-1);
  }
}
