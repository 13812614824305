.draggableColumn {
  display: flex;
  align-items: center;
  height: var(--spacing-48);
  padding: 0 var(--spacing-08);
  background-color: var(--sparky-background);

  &:hover {
    background-color: var(--sparky-grey-200);
  }

  &.isDragging {
    opacity: 0.5;
  }

  &.dragOverlay {
    box-shadow: var(--elevation-2);
  }

  .label {
    width: 9rem;
  }
}
