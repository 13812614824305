.inventoryFilters {
  display: none;
  position: relative;

  &.showFilters {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--sparky-grey-100);
    width: 20rem;
    height: 100%;
  }

  &.showFiltersVVO {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--sparky-grey-100);
    width: 20rem;
    height: 100%;
  }

  .filterHeader {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-12) var(--spacing-12) 0;
    background-color: #fff;
    z-index: 1;
    min-height: 50px;
  }

  .filtersBody {
    overflow-y: auto;
    width: inherit;

    &.withoutExtraFilters {
      padding-top: var(--spacing-12);
    }

    .extraFilters {
      display: flex;
      flex-direction: column;
      padding: var(--spacing-12);
    }
  }
}

.boxshadowLeft {
  box-shadow: var(--sparky-boxshadow-left);
}

.boxshadowRight {
  box-shadow: var(--sparky-boxshadow-right);
}
