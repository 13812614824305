.inventoryConfigSelect {
  display: flex;
  align-items: center;
  gap: var(--spacing-08);

  &[data-state='open'] {
    .icon {
      transform: rotate(180deg);
    }
  }

  .selectName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.inventoryConfigSelectPopover {
  display: flex;
  flex-direction: column;
  background-color: var(--sparky-brand-white);
  padding: var(--spacing-16);
  box-shadow: var(--elevation-2);
  border-radius: var(--border-radius-04);
  min-width: 300px;
  max-height: 480px;

  .searchInput {
    margin-bottom: var(--spacing-08);
  }

  .optionsContainer {
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .option {
      padding: var(--spacing-08);
      display: flex;
      gap: var(--spacing-08);
      align-items: center;
      min-height: var(--spacing-40);
      cursor: default;
      justify-content: space-between;

      .custom:hover {
        cursor: pointer;
      }

      &:hover {
        cursor: pointer;
        background-color: var(--sparky-grey-100);
      }
    }
  }

  .createNewTableView {
    padding: var(--spacing-08);
    display: flex;
    gap: var(--spacing-08);
    align-items: center;
    min-height: var(--spacing-40);
    cursor: default;

    &:hover {
      background-color: var(--sparky-grey-100);
    }
  }

  .divider {
    margin: 6px 0;
  }
}
