.segmentNavDropdownWrapper {
  display: flex;
}

.active {
  color: var(--sparky-red-400);
}

.segmentNavDropdown {
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 100%;
  max-height: 850px;
  overflow: hidden;
}

.segmentLink {
  color: black;
  text-decoration: none;
  padding-block: 8px;
  padding-left: 8px;

  &:hover {
    background-color: var(--sparky-grey-100);

    .segmentLinkText {
      font-weight: 700 !important;
    }
  }
}

.segmentDropdownSection {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &Title {
    padding-block: 16px;
  }
}

.segmentWrapper {
  width: 100%;
  overflow: auto;
  padding-left: 8px;
}

.segmentSection {
  display: flex;
  flex: 1;
  overflow: hidden;
}
