.summarySection {
  padding: 0 12px;

  &.openState {
    background-color: var(--sparky-background);
  }

  .panel {
    width: 100%;
    padding: 16px;

    .topSection,
    .bottomSection {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      gap: 8px 16px;
    }

    .topSection {
      grid-template-rows: repeat(4, auto);
    }

    .bottomSection {
      grid-template-rows: repeat(2, auto);
    }
  }
}
