.loadingState,
.errorState {
  position: relative;
  max-width: 840px;
  min-width: 656px;

  .closeBtn {
    position: absolute;
    top: 16px;
    right: 16px;
  }
}

.vinDetailSidebar {
  position: relative;
  border: 1px solid var(--sparky-grey-100);
  overflow: hidden;
  max-width: 840px;
  min-width: 656px;

  .vinDetailContentContainer {
    overflow-y: auto;
  }
}
