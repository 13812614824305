.chatContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
}

.clearChat {
  margin: 0 auto 0;
  background-color: transparent;
}

.clearChatButton {
  background-color: transparent !important;
}
