.aspectRatioBox {
  position: relative;

  &.aspectRatioBox > * {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &.aspectRatioBox > img,
  &.aspectRatioBox > video {
    object-fit: cover;
  }
}

@supports (--custom: property) {
  .aspectRatioBox:before {
    height: 0;
    content: '';
    display: block;
    padding-bottom: calc(100% / var(--aspect-ratio));
  }
}
