.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: unset;

  background-color: unset;
  font-family: inherit;
  text-decoration: unset;
  transition: all ease-in-out 0.2s;

  &:focus {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0), 0 0 0 3px var(--border-focused);
  }

  .loadingContainer {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .leftIcon,
    .rightIcon {
      display: flex;
      align-items: center;
    }

    .leftIcon {
      padding-right: 8px;
    }

    .rightIcon {
      padding-left: 8px;
    }

    .child {
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;

      .hoverChild {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        visibility: hidden;
        white-space: nowrap;
        line-height: normal;
        font-weight: 500;
      }

      .normalChild {
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        visibility: visible;
        white-space: nowrap;
        line-height: normal;
      }
    }
  }

  &.sm {
    height: 32px;
    font-size: 12px;
    padding: 8px 12px;

    .content {
      height: 16px;

      .leftIcon,
      .rightIcon {
        display: flex;
        align-items: center;
      }

      .leftIcon {
        padding-right: 2px;
      }

      .rightIcon {
        padding-left: 2px;
      }
    }
  }

  &.md {
    height: 44px;
    //min-width: 100px;
    font-size: 16px;
    padding: 12px 16px;

    .content {
      height: 20px;
    }
  }

  &.lg {
    height: 48px;
    //min-width: 100px;
    font-size: 20px;
    padding: 12px 16px;

    .content {
      height: 24px;
    }
  }

  &.solid {
    color: var(--button-text-01);
    border-radius: 12px;

    &.default {
      background-color: var(--button-primary);
      border-color: var(--button-primary);

      &:hover:not([disabled]) {
        background-color: var(--button-hover-01);
        border-color: var(--button-hover-01);
      }
    }

    &.danger {
      border-color: var(--button-danger-primary);
      background-color: var(--button-danger-primary);

      &:hover:not([disabled]) {
        background-color: var(--button-danger-hover-01);
        border-color: var(--button-danger-hover-01);
      }
    }

    &.disabled {
      border-color: var(--button-primary-disabled-border);
      background-color: var(--button-primary-disabled);
    }
  }

  &.outline {
    border-width: 1px;
    border-style: solid;
    background-color: var(--button-secondary);
    border-radius: 12px;

    &.default {
      border-color: var(--button-secondary-border);
      color: var(--button-text-02);

      &:hover:not([disabled]) {
        border-color: var(--button-secondary-border-hover);
        background-color: var(--button-hover-02);
      }
    }

    &.danger {
      border-color: var(--button-danger-primary);
      color: var(--button-danger-text-02);

      &:hover:not([disabled]) {
        border-color: var(--button-danger-secondary-outline-hover);
        background-color: var(--button-danger-hover-02);
      }
    }

    &.disabled {
      border-color: var(--button-secondary-disabled-border);
      color: var(--button-text-disabled-02);
    }

    &.sm {
      padding: 7px 11px;
    }

    &.md {
      padding: 11px 15px;
    }

    &.lg {
      padding: 11px 15px;
    }
  }

  &.text {
    .content {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border-radius: 4px;
      background-color: var(--sparky-brand-white);
    }

    &.default {
      color: var(--button-text-02);

      .content:hover {
        background-color: var(--button-hover-02);
      }
    }

    &.danger {
      color: var(--button-danger-text-02);

      .content:hover {
        background-color: var(--button-danger-hover-02);
      }
    }

    &.disabled {
      color: var(--button-text-disabled-02);

      .content:hover {
        background-color: unset;
      }
    }

    &.sm {
      padding: 4px 0;

      .content {
        padding: 4px;
        height: 20px;
      }
    }

    &.md {
      padding: 6px 0;

      .content {
        padding: 6px 4px;
        height: 32px;
      }
    }

    &.lg {
      padding: 6px 0;

      .content {
        padding: 6px 4px;
        height: 36px;
      }
    }
  }

  &.transparent {
    .content {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border-radius: 4px;
      background-color: transparent;
      color: var(--sparky-text-white);
    }

    &.sm {
      padding: 4px 0;

      .content {
        padding: 4px;
        height: 20px;
      }
    }

    &.md {
      padding: 6px 0;

      .content {
        padding: 6px 4px;
        height: 32px;
      }
    }

    &.lg {
      padding: 6px 0;

      .content {
        padding: 6px 4px;
        height: 36px;
      }
    }
  }

  &:not([disabled]):hover {
    cursor: pointer;

    .content {
      .child {
        .hoverChild {
          visibility: visible;
        }

        .normalChild {
          visibility: hidden;
        }
      }
    }
  }
}
