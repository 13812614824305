.breadcrumbs {
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: var(--spacing-24);
  border-bottom: 1px solid var(--sparky-grey-100);

  .maxWidthContainer {
    display: flex;
    flex: 1;
    align-items: center;

    .betaIcon {
      margin-left: 12px;
    }
  }

  .subTitleContainer {
    display: flex;
    align-items: center;

    .subTitle:hover {
      cursor: default;
    }

    .subTitleUnderline:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    .chevron {
      margin: 0 var(--spacing-12);
      transform: translateY(var(--spacing-02));
    }
  }
}
