.insightsSection {
  padding: 0 12px;

  &.openState {
    background-color: var(--sparky-background);
  }

  .subTitle {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .panel {
    width: 100%;
    padding: 16px;

    .insightsAnswer {
      padding: 8px 0 24px 24px;
    }
  }
}

.markdown {
  div {
    white-space: pre-wrap;
  }

  h2 {
    font-size: 18px;
    margin: 12px 0 4px;
    color: var(--sparky-grey-600);
  }

  p {
    margin: 4px 0;
    font-size: 16px;
  }

  strong {
    color: var(--sparky-grey-600);
    font-weight: 600;
  }

  ol {
    list-style: decimal;
    padding-left: 2rem;
    margin: 4px 0;
  }

  ul {
    margin: 4px 0;

    li {
      list-style-type: disc;
    }
  }
}
