.numberInputField {
  width: 287px;

  .labelField {
    display: flex;
    height: 32px;
    padding: 4px 0;
    font-weight: 400;

    .label {
      font-size: 16px;
    }

    .info {
      cursor: default;
      font-size: 12px;
      text-decoration: underline;
      color: var(--sparky-blue-500);
    }
  }

  .selectInput {
    width: 100%;
  }

  .message {
    font-size: 12px;
    font-weight: 400;
    padding: 6px 6px 0 6px;

    &.default {
      color: var(--sparky-grey-500);
    }

    &.success {
      color: var(--sparky-green-500);
    }

    &.warning {
      color: var(--sparky-orange-500);
    }

    &.error {
      color: var(--sparky-red-500);
    }

    &.disabled {
      color: var(--sparky-grey-500);
    }
  }
}
