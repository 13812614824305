.noCustomizedColumns {
  gap: var(--spacing-08);
}

.noCustomizedColumns,
.tableColumns {
  display: flex;
  flex-direction: column;
  padding: 0 var(--spacing-12) var(--spacing-12);
  overflow-y: auto;
}
