.message {
  width: 100%;
  padding: var(--spacing-12) var(--spacing-08);
  display: flex;
  border-radius: 2px;

  &.elevated {
    box-shadow: var(--elevation-2);
  }

  .icon {
    width: 20px;
    margin-top: var(--spacing-02);
  }

  .centerColumn {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: var(--spacing-04);
  }

  .content {
    &.withHeading {
      margin-top: var(--spacing-04);
    }

    a {
      text-decoration: underline;
    }
  }

  .controlIcon {
    display: flex;
    align-items: flex-start;
    padding-left: var(--spacing-04);

    button {
      display: flex;
    }

    svg {
      cursor: pointer;
    }
  }

  &[data-variant='muted'] {
    &[data-category='default'] {
      background-color: var(--status-default-bg);

      .icon {
        svg {
          fill: var(--status-default);
        }
      }

      .controlIcon {
        svg {
          stroke: var(--status-default);
        }
      }

      .heading,
      .content {
        color: var(--status-default-text);
      }
    }

    &[data-category='info'] {
      background-color: var(--status-info-bg);

      .icon {
        svg {
          fill: var(--status-info);
        }
      }

      .controlIcon {
        svg {
          stroke: var(--status-info);
        }
      }

      .heading {
        color: var(--status-info);
      }

      .content {
        color: var(--status-info-text);
      }
    }

    &[data-category='success'] {
      background-color: var(--status-success-bg);

      .icon {
        svg {
          fill: var(--status-success);
        }
      }

      .controlIcon {
        svg {
          stroke: var(--status-success);
        }
      }

      .heading {
        color: var(--status-success);
      }

      .content {
        color: var(--status-success-text);
      }
    }

    &[data-category='danger'] {
      background-color: var(--status-error-bg);

      .icon {
        svg {
          fill: var(--status-error);
        }
      }

      .controlIcon {
        svg {
          stroke: var(--status-error);
        }
      }

      .heading {
        color: var(--status-error);
      }

      .content {
        color: var(--status-error-text);
      }
    }

    &[data-category='warning'] {
      background-color: var(--status-warning-bg);

      .icon {
        svg {
          color: var(--status-warning);
        }
      }

      .controlIcon {
        svg {
          stroke: var(--status-warning);
        }
      }

      .heading {
        color: var(--status-warning);
      }

      .content {
        color: var(--status-warning-text);
      }
    }
  }

  &[data-variant='outline'] {
    background-color: var(--sparky-brand-white);

    &[data-category='default'] {
      border: 1px solid var(--status-default);

      .icon {
        svg {
          fill: var(--status-default);
        }
      }

      .controlIcon {
        svg {
          stroke: var(--status-default);
        }
      }

      .heading,
      .content {
        color: var(--status-default-text);
      }
    }

    &[data-category='info'] {
      border: 1px solid var(--status-info);

      .icon {
        svg {
          fill: var(--status-info);
        }
      }

      .controlIcon {
        svg {
          stroke: var(--status-info);
        }
      }

      .heading {
        color: var(--status-info);
      }

      .content {
        color: var(--status-info-text);
      }
    }

    &[data-category='success'] {
      border: 1px solid var(--status-success);

      .icon {
        svg {
          fill: var(--status-success);
        }
      }

      .controlIcon {
        svg {
          stroke: var(--status-success);
        }
      }

      .heading {
        color: var(--status-success);
      }

      .content {
        color: var(--status-success-text);
      }
    }

    &[data-category='danger'] {
      border: 1px solid var(--status-error);

      .icon {
        svg {
          fill: var(--status-error);
        }
      }

      .controlIcon {
        svg {
          stroke: var(--status-error);
        }
      }

      .heading {
        color: var(--status-error);
      }

      .content {
        color: var(--status-error-text);
      }
    }

    &[data-category='warning'] {
      border: 1px solid var(--status-warning);

      .icon {
        svg {
          fill: var(--status-warning);
        }
      }

      .controlIcon {
        svg {
          stroke: var(--status-warning);
        }
      }

      .heading {
        color: var(--status-warning);
      }

      .content {
        color: var(--status-warning-text);
      }
    }
  }
}
