.checkboxContainer {
  --offset: 0;
  --checkbox-width: 24px;

  position: relative;
  display: inline-flex;
  align-items: center;
  height: var(--checkbox-width);
  width: var(--checkbox-width);
  transition: all ease-in-out 0.2s;

  &:hover[data-disabled='false'] {
    background-color: var(--bg-hover);

    &.defaultHoverState {
      border-radius: 50%;
      box-shadow: 0 0 0 calc(var(--checkbox-width) * 0.5) var(--bg-hover);
    }

    &.smallHoverState {
      border-radius: 2.5px;
      box-shadow: 0 0 0 4px var(--bg-hover);
    }

    input[type='checkbox']:focus + .iconWrapper {
      box-shadow: 0 0 0 1px var(--bg-hover), 0 0 0 3px var(--border-focused);
    }

    &.error {
      border-radius: 50%;
      box-shadow: 0 0 0 calc(var(--checkbox-width) * 0.5) var(--sparky-red-100);

      input[type='checkbox']:focus + .iconWrapper {
        box-shadow: 0 0 0 1px var(--sparky-red-100), 0 0 0 3px var(--status-error);
      }
    }

    .iconWrapper {
      .inactive {
        border-color: var(--border-strong-01);
        background-color: var(--bg-hover);
      }
    }
  }

  input[type='checkbox'] {
    -webkit-appearance: none;
    appearance: none;
    position: absolute;
    top: var(--offset);
    bottom: var(--offset);
    left: var(--offset);
    right: var(--offset);
    margin: 0;
    outline: none;
    cursor: pointer;

    &:focus + .iconWrapper {
      box-shadow: 0 0 0 1px var(--sparky-brand-white), 0 0 0 3px var(--border-focused);
    }

    &:disabled + .iconWrapper {
      .checkbox {
        background-color: var(--bg-selected-disabled);
        border-color: var(--bg-selected-disabled);

        &.inactive {
          border-width: 1.5px;
          border-style: solid;
          border-color: var(--border-subtle-02);
          background-color: var(--bg);
        }
      }
    }
  }

  &.error {
    input[type='checkbox'] {
      border-color: var(--status-error);
      background-color: var(--sparky-red-100);
      box-shadow: 0 2px 4px 0 #ff000026;

      + .iconWrapper {
        box-shadow: 0 0 0 1px var(--sparky-brand-white), 0 0 0 3px var(--status-error);
      }
    }
  }

  .iconWrapper {
    transition: all ease-in-out 0.2s;
    border-radius: 3px;
    height: var(--checkbox-width);
    width: var(--checkbox-width);

    .checkbox {
      display: inline-block;
      height: var(--checkbox-width);
      width: var(--checkbox-width);
      background-color: var(--bg);
      border-radius: 4px;
      stroke: var(--bg);
      stroke-width: 2;
      flex-shrink: 0;
      transition: all ease-in-out 0.2s;

      &.active {
        background-color: var(--bg-selected);
      }

      &.inactive {
        transition: all ease-in-out 0.2s;
        border-width: 1.5px;
        border-style: solid;
        border-color: var(--border-strong-01);
      }
    }
  }
}
