.closedControlPanel {
  position: absolute;
  top: var(--spacing-16);
  left: var(--spacing-24);
  z-index: 5;
  pointer-events: all;
  display: flex;
  align-items: center;
  column-gap: var(--spacing-12);

  button {
    background-color: var(--sparky-brand-white);
  }

  .compactSummaryContainer {
    display: flex;
    padding: var(--spacing-08) var(--spacing-24);
    background-color: var(--sparky-brand-white);
    column-gap: var(--spacing-24);
    box-shadow: var(--sparky-elevation-3);
    border-radius: 4px;

    .compactSummary {
      display: flex;
      column-gap: var(--spacing-08);
      padding: 2px 0;

      span {
        display: flex;
      }
    }
  }
}

.summaryPanel {
  position: absolute;
  top: var(--spacing-16);
  left: var(--spacing-24);
  z-index: 5;
  pointer-events: all;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-24) var(--spacing-24) var(--spacing-12);
  box-shadow: var(--sparky-elevation-3);

  .divider {
    border-top: 1px solid var(--sparky-grey-200);
    margin: 0 0 20px 0;
  }

  .topContainer {
    display: flex;
    flex-direction: column;
    row-gap: var(--spacing-16);
    margin-bottom: var(--spacing-24);
    position: relative;

    .togglePanelButton {
      position: absolute;
      right: -52px;
      top: var(--spacing-12);

      button {
        background-color: var(--sparky-brand-white);
      }
    }

    .summaryBlock {
      display: flex;
      flex-direction: column;
      row-gap: var(--spacing-04);

      .blockTitle {
        display: flex;
        line-height: var(--spacing-24);
      }

      .blockValue {
        line-height: normal;
      }
    }
  }

  .controlContainer {
    border-top: 1px solid var(--sparky-grey-200);
    border-bottom: 1px solid var(--sparky-grey-200);
    padding: var(--spacing-24) 0;
    display: flex;
    flex-direction: column;
    row-gap: var(--spacing-24);
    margin-bottom: var(--spacing-24);
  }

  .legendTitle {
    margin-bottom: var(--spacing-12);
  }

  .legendItemRow {
    display: flex;
    column-gap: var(--spacing-08);
    align-items: center;
    padding: var(--spacing-08) 0;

    .legendIcon {
      display: flex;
      column-gap: var(--spacing-04);
    }
  }

  .scaleLegend {
    width: 250px;
    padding: var(--spacing-16) 0;
    max-width: 100%;

    .gradiantBar {
      width: 100%;
      height: var(--spacing-12);
      margin-top: var(--spacing-08);
    }

    .minMaxRow {
      display: flex;
      flex-direction: 1;
      justify-content: space-between;
      margin-top: var(--spacing-08);
    }
  }
}

.iconPair {
  display: flex;

  svg {
    &:nth-child(2) {
      margin-left: -8px;
    }
  }
}
