.link {
  position: relative;
  display: flex;
  align-items: center;
  padding: var(--spacing-08) var(--spacing-16);

  .normalLinkLabel {
    display: flex;
    align-items: center;
    color: var(--sparky-text-primary);

    &.inactive {
      visibility: hidden;
    }
  }

  .activeLinkLabel {
    visibility: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.active {
      visibility: visible;
      color: var(--sparky-brand-red-default);
    }
  }
}
