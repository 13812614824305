.toast-container {
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  width: fit-content;
  pointer-events: none;
}
