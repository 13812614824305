.poperoverClassName {
  padding: 16px;

  .action {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--sparky-text-primary);
    padding: 8px;
    width: 100%;

    &:hover {
      cursor: pointer;
      background-color: var(--sparky-grey-100);
    }

    .externalLink {
      margin-left: auto;
    }
  }
}
