.checkboxField {
  --checkbox-width: 24px;

  display: inline-flex;
  flex-direction: column;
  width: fit-content;
  font-weight: 400;

  &.disabled {
    color: var(--sparky-grey-300);
  }

  .textField {
    display: flex;
    align-items: center;
    cursor: pointer;

    &[data-align='top'] {
      align-items: flex-start;
    }

    &[data-align='bottom'] {
      align-items: flex-end;
    }

    .label {
      font-size: 16px;
      margin-left: calc(var(--checkbox-width) * 0.5);

      &.checkboxLabelTop {
        padding-top: 2px;
      }

      &.checkboxLabelBottom {
        padding-bottom: 2px;
      }
    }
  }

  .helpText {
    font-size: 12px;
    margin-left: calc(var(--checkbox-width) * 1.5);
    margin-top: 2px;
  }
}
