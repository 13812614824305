.accordion {
  display: flex;
  flex-direction: column;
  width: 100%;

  &.divider {
    border-bottom: 1px solid var(--sparky-grey-100);
  }

  .accordionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--spacing-08);
    margin: var(--spacing-12) 0;
  }

  .childrenWrapper {
    margin-bottom: var(--spacing-08);
  }
}
