.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 12px;
  border-radius: 12px;
  background: var(--layer-01);
  overflow: auto;

  &.selectable {
    border: 1px solid var(--border-subtle-01);
    background-color: white;

    &.selected {
      border: 1px solid var(--border-active-01);
      background-color: var(--layer-01);
    }

    &:hover {
      cursor: pointer;
    }
  }

  .heading {
    display: flex;
    width: 100%;
  }

  .cardBody {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 16px;
  }

  .childrenWrapper {
    width: 100%;
    overflow: auto;
  }

  .guidelines {
    width: 100%;
    border-radius: 2px;
    border: 1px dashed rgba(255, 0, 0, 0.2);
  }
}
